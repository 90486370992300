import s from "./104Masks.module.scss";
import MainWrapper from "../../components/UI/MainWrapper/MainWrapper";
import Header from "./sub-components/Header";
import MasksList from "./sub-components/masks_list";
import { useWallet } from "../../components/AuctionUI/api";
import { useEffect, useState } from "react";
import axios from "axios";

const Masks = () => {
  const [forSaleMasks, setForSaleMasks] = useState([]);
  const wallet = useWallet();

  const getForSaleAssets = async () => {
    try {
      let response = await axios.get("https://krewstudios.com/API/ForSaleAssets.php");
      setForSaleMasks(response.data);
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    getForSaleAssets();
  }, [])

  return (
    <div>
      <section id='home-section' className={s.container}>
        <MainWrapper>
          <Header />
          <MasksList wallet={wallet} forSaleMasks={forSaleMasks} />
        </MainWrapper>
      </section>
    </div>
  );
};

export default Masks;
