import React, { Suspense, useRef } from 'react'
import styles from "./MaskCarousel.module.scss";
import ether from "../../assets/ether.png";
import { Canvas } from '@react-three/fiber'
import { OrbitControls, Stage } from '@react-three/drei'
import Model from './Model'

// https://codesandbox.io/s/re-using-gltfs-dix1y
// https://gltf.pmnd.rs/

const MaskCarouselImage = ( { mask, index, slider, currentSlide }) => {
  const ref = useRef()

  return (
  <div
    className={`${styles.slide} ${ index === currentSlide ? styles.active : "" } keen-slider__slide`}
    key={index} >
      <div className={`${styles.inner}`}>
        <div className={styles.imageContainer}>
        <Canvas shadows dpr={[1, 2]} camera={{ fov: 50 }}>
          <Suspense fallback={null}>
            <Stage controls={ref} preset="rembrandt" intensity={1}  environment="city">
              <Model />
            </Stage>
          </Suspense>
          <OrbitControls ref={ref} autoRotate autoRotateSpeed="1" />
        </Canvas>
        </div>
        <h5>{mask.name}</h5>
        <div className={styles.value}>
          <img src={ether} alt="logo" />
          <p>{mask.value}</p>
        </div>
      </div>
  </div>
  );
};

export default MaskCarouselImage;
