const PlayVideo = () => {
  return (
    <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_b_4_21)">
        <rect x="0.326172" y="0.416992" width="45.2104" height="45.2104" rx="12" fill="#C68C8C" fillOpacity="0.32" />
        <rect x="1.07617" y="1.16699" width="43.7104" height="43.7104" rx="11.25" stroke="url(#paint0_linear_4_21)" strokeWidth="1.5" />
      </g>
      <path d="M17.5341 13.6558C16.4883 13.6985 15.5283 14.5792 15.5283 15.7658V30.2786C15.5283 31.8607 17.2359 32.8987 18.5766 32.132L31.2636 24.8756C32.643 24.0866 32.643 21.9577 31.2636 21.1688L18.5766 13.9124C18.2415 13.7207 17.8827 13.6416 17.5341 13.6558Z" fill="url(#paint1_linear_4_21)" />
      <defs>
        <filter id="filter0_b_4_21" x="-23.6738" y="-23.583" width="93.2104" height="93.2104" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImage" stdDeviation="12" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_4_21" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_4_21" result="shape" />
        </filter>
        <linearGradient id="paint0_linear_4_21" x1="0.326172" y1="0.416992" x2="39.5773" y2="50.3151" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0.2" />
          <stop offset="0.494792" stopColor="#8A92B3" stopOpacity="0" />
          <stop offset="0.9999" stopColor="#899AE4" stopOpacity="0.3" />
          <stop offset="1" stopColor="#D3D6E3" stopOpacity="0.2" />
        </linearGradient>
        <linearGradient id="paint1_linear_4_21" x1="23.9132" y1="13.6542" x2="23.9132" y2="32.39" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="0.433222" stopColor="#E0E0E0" />
          <stop offset="1" stopColor="#EEEEEE" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default PlayVideo;
