/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Model(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/Mask_AR_Gold_Test_V2.gltf')
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Full_Mask_V1.geometry}
        material={materials.Gold}
        position={[0, 14.26, 4.75]}
        scale={[10, 10, 10]}
      />
    </group>
  )
}

useGLTF.preload('/Mask_AR_Gold_Test_V2.gltf')