import { useEffect, useState } from "react";
import s from "./modal.module.scss";
import axios from "axios";

const Modal = (props) => {
  let [name, setName] = useState("");
  let [email, setEmail] = useState("");
  let [error, setError] = useState("");
  let [success, setSuccess] = useState("");
  let [loading, setLoading] = useState(false);

  const reg = /\S+@\S+\.\S+/
  
  const closeModal = () => {
    props.hideModal();
    setEmail("");
    setName("");
    setError("");
    setSuccess("");
  }
  const sendFailedEmail = async () => {
    try {
      const params = new URLSearchParams();
      params.append("tokenName", props.tokenName.split("#")[1]);
      params.append("email", email);

      let response = await axios.post("https://mask-world.io/api/v1/104ClaimFailedEmail.php", params, {
        headers: {
          Authorization: "32tge7h8s9a5n72fr3ed",
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      console.log(response);
    } catch (error) {
      console.log({ error });
    }
  }
  const sendNotifyEmail = async () => {
    try {
      const params = new URLSearchParams();
      params.append("tokenName", props.tokenName.split("#")[1]);
      params.append("email", email);

      let response = await axios.post("https://mask-world.io/api/v1/104ClaimEmail.php", params, {
        headers: {
          Authorization: "32tge7h8s9a5n72fr3ed",
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      console.log(response);
    } catch (error) {
      console.log({ error });
    }
  }
  const setFailedStatus = async () => {
    try {
      const params = new URLSearchParams();
      params.append("tokenName", props.tokenName.split("#")[1]);

      let response = await axios.post("https://krewstudios.com/API/FailedClaimHandler.php", params, {
        headers: {
          Authorization: "32tge7h8s9a5n72fr3ed",
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      console.log(response);
    } catch (error) {
      console.log({ error });
    }
  }
  const saveForm = async () => {
    setError("");
    setSuccess("");
    if (name === "" || email === "") {
      setError("Please fill all the fields");
      return;
    }
    if (!reg.test(email)) {
      setError("Please enter a valid email");
      return;
    }
    setLoading(true);
    try {
      let data = { name, email, address: props.wallet.accountAddress };
      // let data = { name, email, address: "test address" };
      const params = new URLSearchParams();
      params.append("name", data.name);
      params.append("email", data.email);
      params.append("address", data.address);
      params.append("tokenName", props.tokenName.split("#")[1]);
      params.append("tokenId", props.tokenId);
      params.append("ClaimedMask", props.selected);

      let response = await axios.post("SaveInformaionDetails.php", params, {
        headers: {
          Authorization: "32tge7h8s9a5n72fr3ed",
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      setSuccess("Thank you.");
      console.log(response);
      
      props.claim(
        props.wallet.web3,
        props.selected,
        props.wallet.accountAddress,
        props.toast,
        props.update,
        props.hideModal,
        sendNotifyEmail,
        sendFailedEmail,
        setFailedStatus
      );
     // closeModal()
      
    } catch (error) {
      setError("Something went wrong");
      console.log({ error });
    }
    setLoading(false);
  };

  return (
    <>
      {props.show ? (
        <div
          id="modal"
          className={s.bg}
          onClick={(e) =>
            e.target.getAttribute("id") == "modal" ? closeModal() : null
          }
        >
          <div className={s.modal}>
            <h1>SUBMIT DETAIL</h1>
            <div className={s.form}>
              <input
                type="text"
                placeholder="Your full name"
                value={name}
                onChange={(e) => (setError(""), setName(e.target.value))}
              />
              <input
                type="email"
                placeholder="Email address"
                value={email}
                onChange={(e) => (setError(""), setEmail(e.target.value))}
              />
              <p className={s.alert}>{error}</p>
              <p className={s.success}>{success}</p>
            </div>

            <button
              onClick={() => {
                if (loading) return;
                saveForm();
                //API handling this part now, worth to test it tho!
                
              }}
            >
              {loading ? "Please wait..." : "SUBMIT"}
            </button>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Modal;
