import s from "./header.module.scss";

const Header = () => {
  return (
    <div className={s.headerBanner}>
      <div className={s.container}>
        <div className={s.topContainer}>
          <h1 className={s.textCenter}>Jordi Molla NFT Collection</h1>
          <p className={s.textCenter}>
            {` He is an actor, but he’d go as far as describing everyone as an actor.
          We’re all hiding behind one. It’s not that we think being our true
          self is a sin or shameful, it’s just that there exists a different
          version of ourselves in each person's mind that we encounter.Human
          beings are far too complex, and that's where the beauty comes from.
          Even the simplest of things have a thousand faces.`}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Header;
