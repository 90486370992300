import s from "./DualJordiLink.module.scss";

const DualJordiLink = () => {
  return (
    <div className={s.container}>
      <div className={s.item}>
        <h1>104 rare masks</h1>
        <p>
          {`
          Jordi Mollá’s genesis NFT project  was inspired by 104 original, hand painted masks. Jordi describes himself and everyone as an actor, hiding behind a mask. Further explaining that we hold different versions of ourselves with each human interaction. The beauty is in the complexity. 
          `}
          <br />
          {`
          The digital collection sold out within minutes. Krew are pleased to announce that 104 of the lucky ultra rare Mask holders are now able to claim their physical exclusive art. 
          `}
          <br />
          {`For further information about how to claim, please join the MASK discord and follow Twitter. 
          `}
        </p>
        <a href="/104_masks" className={s.button}>
          Explore & Claim
        </a>
      </div>
      <div className={s.item}>
        <h1>Masks world</h1>
        <p>
          {`
         The Mask team has been hinting at a MASK WORLD. Krew are happy to reveal we have been busy developing an interactive metaverse, giving Mask holders exclusive entry into the immersive space. Being a Mask holder will allow you entry into MASK WORLD.
         `}
          <br />
          {`
         The space will give the community access to further exclusive Jordi Mollá artwork as well as incorporate other exciting elements, yet to be announced.
          `}
        </p>
        <a href="#" className={s.button}>
          COMING SOON
        </a>
      </div>
    </div>
  );
};

export default DualJordiLink;
