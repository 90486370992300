import s from "./hero.module.scss";
import Book_0 from "../../../../assets/book/book_0.png";
import Book_1 from "../../../../assets/book/book_1.png";
import Book_2 from "../../../../assets/book/book_2.png";
import Sign_0 from "../../../../assets/book/sign_0.png";
import Sign_1 from "../../../../assets/book/sign_1.png";

const Hero = (props) => {
  return (
    <div className={s.container}>
      <div className={s.content}>
        <div className={s.book_section}>
          <div className={s.left_screenshot}>
            <div className={s.wrapper}>
              <img src={Sign_0} className={s.sign} alt="" />
              <img src={Book_0} className={s.book} alt="" />
            </div>
          </div>
          <div className={s.center_book}>
            <img src={Book_1} className={s.center_screenshot} alt="" />
            {/* {props.connected ? (
              <div className={s.open_book} onClick={props.openBook}>
                OPEN THE BOOK
              </div>
            ) : null} */}
          </div>
          <div className={s.right_screenshot}>
            <div className={s.wrapper}>
              <img src={Sign_1} className={s.sign} alt="" />
              <img src={Book_2} className={s.book} alt="" />
            </div>
          </div>
        </div>
        <div className={s.desc}>
          <h1>{`Jordi Molla’s book nft`}</h1>
          <p>
            {`Jordi Molla’s book is itself a remarkable work of art. It is a small
          edition number of very high quality books that this artist created for
          his inner circle of collectors and fellow artists. It gives us an
          intimate glimpse into Jordi’s home, atelier and his working process, a
          raw, poignant day by day diary that brings the viewers a personal and
          unfiltered behind the scenes view.`}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Hero;
