import PoolCard from "./PoolCard"
import ether from "../../assets/ether.png";
import styles from "./PoolUI.module.scss";
import { Suspense, useRef, useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import { queryByTestId } from "@testing-library/dom";
import { ETHERSCAN_ADDRESS, fromWei, getContract, mint, PointType, PoolInfo, useCountdown, usePointBalance, usePools } from "../AuctionUI/api";
import imgRainbow from '../../assets/points1.png';
import imgUnicorn from '../../assets/points2.png';
import BigNumber from "bignumber.js";
import whitelist from '../../data/whitelist.json';
import { toast } from "react-toastify";
import Model from '../MaskCarousel/Model'
import { Canvas } from "@react-three/fiber";
import { OrbitControls, Stage } from "@react-three/drei";
import maskAnimation from '../../assets/mask-animation.mp4';

const Pool = {
  PUBLIC: 0,
  UNICORN: 1,
  MIXED: 2,
};




const PoolUI = ({ wallet }) => {
  const { accountAddress, connectWallet, web3, ethBalance, getEthBalance } = wallet;
  const [ cart, setCart ] = useState([0, ...Object.values(PoolInfo).map(() => 0)]);
  const [ cartPointType, setCartPointType ] = useState([0, ...Object.values(PoolInfo).map(pool => pool.defaultPointType)])
  const [ modal, setModal ] = useState();
  const [ isCheckingOut, setIsCheckingOut ] = useState();

  const { pools, maxMintsPerTx, minPointsPercentToMint, unicornToEth, rainbowToEth, mintingFeeBasisPoints, getPools } = usePools(web3, accountAddress);
  const { balances, getBalances } = usePointBalance(web3, accountAddress)
  const { rainbows, unicorns } = balances;

  const cartTotal = cart.reduce((a, b) => a + b, 0);

  function viewCart() {
    setModal('cart')
  };

  function addToCart(index) {
    setAmount(index, 1);
  }

  function clearCart() {
    setCart([0,0,0,0]);
  }

  function removeFromCart(index) {
    setAmount(index, 0);
  }

  function setAmount(index, qty) {
    setCart(x => {
      const y = [...x];
      y[index] = qty;
      return y;
    })
  }

  function setPointType(index, type) {
    setCartPointType(x => {
      const y = [...x];
      y[index] = type;
      return y;
    })
  }

  const rainbowAmount = fromWei(rainbows).toFixed(0);
  const unicornAmount = fromWei(unicorns).toFixed(0);

  const cartItems = cart
    .map((qty, index) => {
      const pool = pools[index - 1] ?? {};
      return { ...pool, qty, pointType: cartPointType[index] || pool.defaultPointType }
    }).filter(x => x.qty);

  const totalEthCost = cartItems.reduce((total, item) => total.plus(BigNumber(item.costInEth).times(item.qty)), BigNumber(0));
  const totalCostRainbow = cartItems.reduce((total, item) => total.plus(item.pointType === PointType.RAINBOW ? item.costInRainbows * item.qty : 0), BigNumber(0));
  const totalCostUnicorn = cartItems.reduce((total, item) => total.plus(item.pointType === PointType.UNICORN ? item.costInUnicorns * item.qty : 0), BigNumber(0));
  

  const allSelected = cartItems.every(item => item.pointType !== PointType.NONE);

  const futureRainbowBalance = fromWei(rainbows).minus(totalCostRainbow);
  const futureUnicornBalance = fromWei(unicorns).minus(totalCostUnicorn);
  const rainbowPercent = !totalCostRainbow.eq(0) ? fromWei(rainbows).div(totalCostRainbow).times(100).toNumber() : 100;
  const unicornPercent = !totalCostUnicorn.eq(0) ? fromWei(unicorns).div(totalCostUnicorn).times(100).toNumber() : 100;
  const enoughRainbows = rainbowPercent >= minPointsPercentToMint;
  const enoughUnicorns = unicornPercent >= minPointsPercentToMint;
  
  
  
  let topupFee = BigNumber(0);
  
  if (futureRainbowBalance.lt(0)) {
    topupFee = futureRainbowBalance.times('-1').div(rainbowToEth);
  }
  
  if (futureUnicornBalance.lt(0)) {
    topupFee = topupFee.plus(futureUnicornBalance.times('-1').div(unicornToEth));
  }

  const rainbowFee = totalCostRainbow.times(mintingFeeBasisPoints / 10000).div(rainbowToEth); 
  const unicornFee = totalCostUnicorn.times(mintingFeeBasisPoints / 10000).div(unicornToEth); 
  const fee = rainbowFee.plus(unicornFee);
  
  const futureEthBalance = BigNumber(ethBalance).minus(totalEthCost).minus(fee);

  const totalRainbowAmount = BigNumber.min(totalCostRainbow, fromWei(rainbows).toFixed(0));
  const totalUnicornAmount = BigNumber.min(totalCostUnicorn, fromWei(unicorns).toFixed(0));
  
  const totalEth = fromWei(totalEthCost).plus(topupFee).plus(fee);

  const enoughEth = futureEthBalance.gte(0);
  const disabled = !enoughEth || !enoughRainbows || !enoughUnicorns || !allSelected;

  let errorMessage = '';

  if (!enoughEth) errorMessage = 'Not enough ETH to check out';
  if (!enoughRainbows) errorMessage = `Not enough rainbows. You need a minimum of ${minPointsPercentToMint}% percent of the total to check out`;
  if (!enoughUnicorns) errorMessage = `Not enough unicorns. You need a minimum of ${minPointsPercentToMint}% percent of the total to check out`;
  if (!enoughUnicorns && !enoughRainbows) errorMessage = `Not enough rainbows or unicorns. You need a minimum of ${minPointsPercentToMint}% percent of the total to check out`;
  if (!allSelected) errorMessage = 'Some of your items can be paid in rainbows OR unicorns, you must select one or the other before you can proceed';

  const whiteListData = whitelist[wallet.accountAddress];

  async function mintMasks() {
    setIsCheckingOut(true);
    const error = await mint({
      provider: web3,
      cart,
      cartPointType,
      totalEth,
      sig: whiteListData?.sig,
      maxMints: whiteListData?.maxMints,
      address: wallet.accountAddress,
      setModal,
    });
    setIsCheckingOut(false);
    if (!error) {
      clearCart();
      getPools();
      getBalances();
      getEthBalance();
    }
    
  }

  // debugger;
  return <div className={styles.container}>
  {modal === 'cart' &&
      <div className={styles.ModalBackground} onClick={() => setModal()}>
        <div className={styles.ModalBox} onClick={(e) => e.stopPropagation()}>
          <div className={styles.ModalHeader}>
            Shopping Cart
            
            <button className={styles.IconButton} onClick={() => setModal()}><CloseIcon fontSize="large" /></button>
          </div>
          <div className={styles.ModalSecton}>
            <div className={styles.costTableContainer}>
              <div className={styles.pointContainer}>
                <img src={ether} alt="ETH logo" className={styles.eth}/>
                <span>{fromWei(ethBalance).toFixed(3)}</span>
                <img src={imgRainbow}  alt="Rainbow logo" className={styles.points}/>
                <span>{rainbowAmount}</span>
                <img src={imgUnicorn}  alt="Unicorn logo" className={styles.points}/>
                <span>{unicornAmount}</span>
              </div>
            </div>
            
            <div className={styles.cartItemListContainer}>
              
              <ul className={styles.cartItemList}>
                {cartItems.map(item => {
                  console.log(item);
                  return <CartItem
                    key={item.id}
                    whiteListData={whiteListData} 
                    item={item} 
                    maxMintsPerTx={maxMintsPerTx} 
                    setAmount={setAmount}
                    setPointType={setPointType}
                    removeFromCart={removeFromCart}
                  />
                })}
              </ul>
            </div>
            <div className={styles.costTableContainer}>
              <div className={styles.warningText}>{errorMessage}</div>
            </div>
            <div className={styles.costTableContainer}>
              
              <div className={styles.costTableCard}>
                <div className={styles.costTableTitle}>
                  Total Expenditure
                  <div className={styles.costTableDollarIcon}/>
                </div>
                <div className={styles.costTableRow + ' ' + styles.rainbow}>
                  <div>Rainbows</div>
                  <div>{totalRainbowAmount.toFixed(0)}</div>
                </div>
                <div className={styles.costTableRow + ' ' + styles.unicorn}>
                  <div>Unicorns</div>
                  <div>{totalUnicornAmount.toFixed(0)}</div>
                </div>
                {topupFee.gt(0) &&
                  <div className={styles.costTableRow + ' ' + styles.eth}>
                    <div>Topup Fee</div>
                    <div>{trimZeros(topupFee.toFixed(4))}</div>
                  </div>
                }
                <div className={styles.costTableRow + ' ' + styles.eth}>
                  <div>Total ETH <span style={{ fontSize: '14px'}}>(not including gas fees)</span></div>
                  <div>{trimZeros(totalEth.toFixed(4))}</div>
                </div>
                
                {/* <div>
                  <div>Rainbows: {totalRainbowAmount.toFixed(0)}</div>
                  <div>Unicorn cost: {totalUnicornAmount.toFixed(0)}</div>
                  <div>ETH base cost: {fromWei(totalEthCost).toFixed(4)}</div>
                  <div>Topup fee (ETH): {topupFee.toFixed(4)}</div>
                  <div>Minting fee (ETH): {fee.toFixed(4)}</div>
                  <div>Eth cost: {totalEth.toFixed(4)}</div>
                </div> */}
              </div>
              
            </div>
            <div className={styles.costTableFineprint}>ETH value includes {trimZeros(fee.toFixed(4))}Ξ in platform fees</div>
          </div>
          

          <div className={styles.ModalSecton}>
            <div className={styles.ModalButtonContainer}>
                <button className={styles.cancelButton} onClick={() => setModal()}>Keep Shopping</button>
                {accountAddress ?
                  (isCheckingOut ? 
                    <button className={styles.cartButton} style={{ width: '200px'}}>Checking out...</button>
                    :
                    <button className={styles.cartButton} style={{ width: '150px'}} disabled={disabled} onClick={mintMasks}>Check out</button>
                  )
                  :
                  <button className={styles.cartButton} style={{ width: '200px'}} onClick={() => connectWallet(getPools)}>Connect wallet</button>
                }
            </div>
          </div>
          
        </div>
      </div>
    }
    {cartTotal > 0 &&
      <div className={styles.cartBlob} onClick={viewCart}>View Cart</div>
    }
    {pools.map(pool => {
      return (
        <PoolType 
          address={accountAddress}
          pool={pool}
          cart={cart}
          addToCart={addToCart}
          viewCart={viewCart}
          connectWallet={connectWallet}
        />
      );
    })

    }
  </div>
}

export default PoolUI;

export function trimZeros(str) {
  let end = str.length;
  while(end > 0 && str[end - 1] === '0') --end;
  const trimmed = (end < str.length) ? str.substring(0, end) : str;
  return (trimmed.endsWith('.') ? trimmed.slice(0, trimmed.length - 1) : trimmed) || '0';
}

const CartItem = ({ item, maxMintsPerTx = 0, setAmount, setPointType, removeFromCart, whiteListData}) => {
  const qty = item.qty;
  const pointType = item.pointType;
  const supplyLimit = item.supply - item.totalMinted;
  const maxMints = item.requiresWhitelist ? Math.min(item.maxMintsPerAddress, whiteListData.maxMints) : item.maxMintsPerAddress;
  const userLimit = maxMints - item.userMinted;
  const ref = useRef()

  let maxQty = Math.min(supplyLimit, userLimit, maxMintsPerTx);

  if (item.maxMintsPerAddress === 0) maxQty = Math.min(supplyLimit, maxMintsPerTx);
  if (maxQty <= 0) return null;

  const qtyRange = [];
  for (let n = 1; n <= maxQty; n++) {
    qtyRange.push(n);
  }

  let priceSection;
  if (!item.costInEth) {
    priceSection = <div className={styles.cartItem_priceSection}>
      {!!item.costInRainbows && 
        <button 
          className={`${styles.pointButton} ${pointType === PointType.RAINBOW ? styles.selected : ''}`}
          onClick={() => setPointType(item.id, PointType.RAINBOW)}
        >
          <img src={imgRainbow}  alt="Rainbow logo" className={styles.points}/> 
          <p>{item.costInRainbows * qty}</p>
        </button>} 
      {!!item.costInRainbows && !!item.costInUnicorns && <span className={styles.or}>or</span>} 
      {!!item.costInUnicorns && 
        <button 
          className={`${styles.pointButton} ${pointType === PointType.UNICORN ? styles.selected : ''}`} 
          onClick={() => setPointType(item.id, PointType.UNICORN)}
        >
          <img src={imgUnicorn}  alt="Unicorn logo" className={styles.points}/> 
          <p>{item.costInUnicorns * qty}</p>
        </button>} 
        {pointType === PointType.NONE && 
          <span className={styles.selectPointType}>← Select Point Type</span>
        }
    </div>
  } else {
    priceSection = <div className={styles.cartItem_priceSection}>
      <div className={styles.pointButton + ' ' + styles.selected}>
        <img src={ether} alt="ETH logo" className={styles.eth}/>
        <p>{trimZeros(fromWei(item.costInEth).times(qty).toFixed(4))}</p>
      </div>
    </div>
  }

  return (
    <li key={item.id}>
      <div className={styles.cartItem_selectContainer}>
        <select className={styles.cartItem_select} value={qty} onChange={e => setAmount(item.id, parseInt(e.target.value))}>
          {qtyRange.map(n => <option>{n}</option>)}
        </select>
      </div>
      <div className={styles.cartItem_image}>
        <video
          style={{ width: '100%', borderRadius: '12px'}}
          src={maskAnimation}
          playsInline
          loop
          autoPlay
          muted
          //poster={KrewVideoCover}
          // onCanPlay={canPlayHandler}
          // onCanPlayThrough={canPlayThroughHandler}
        >
          <source src="KrewVideo" type="video/mp4" />
          Your browser does not support HTML5 video.
        </video>
        {/* <Canvas shadows dpr={[1, 2]} camera={{ fov: 50 }}>
          <Suspense fallback={null}>
            <Stage controls={ref} preset="rembrandt" intensity={1}  environment="city">
              <Model />
            </Stage>
          </Suspense>
          <OrbitControls ref={ref} autoRotate autoRotateSpeed="10" />
        </Canvas> */}

      </div>
      <div className={styles.cartItem_details}>
        {priceSection}

        <h6>{item.name}</h6>
        <button className={styles.IconButton + ' ' + styles.cartItem_removeButton} onClick={() => removeFromCart(item.id)}>Remove</button>
      </div>

        

      
    </li>
    
  );
}

const PoolType = ({ pool, cart, addToCart, viewCart, address, connectWallet }) => {

  const whiteListData = whitelist[address];
  const onWhitelist = !!whiteListData;

  const isKWL = !!pool.isKwl;

  const needsWhitelisting = pool.requiresWhitelist && !onWhitelist;

  const { days, hours, minutes, seconds, isFinished } = useCountdown(pool.mintTimeStart, 20);

  let priceSection;
  if (!pool.costInEth) {
    priceSection = <>
      {!!pool.costInRainbows && <><img src={imgRainbow}  alt="Rainbow logo" className={styles.points}/> <p>{pool.costInRainbows}</p></>} 
      {!!pool.costInRainbows && !!pool.costInUnicorns && 'or'} 
      {!!pool.costInUnicorns && <><img src={imgUnicorn}  alt="Unicorn logo" className={styles.points}/> <p>{pool.costInUnicorns}</p></>} 
    </>
  } else {
    priceSection = <>
      <img src={ether} alt="ETH logo" className={styles.eth}/>
      <p>{fromWei(pool.costInEth).toFixed()}</p>
    </>
  }

  const mintedOut = !isKWL; //pool.supply <= pool.totalMinted;

  let status =  <div className={styles.available}>Available</div>;
  if (!isFinished) status = <div className={styles.comingSoon}>Coming Soon</div>;
  if (mintedOut) status = <div className={styles.minted}>Minted Out</div>;
  if (isKWL) status = '';


  let button = <a href='https://opensea.io/collection/jordimolla-masks' target='_blank'><button className={styles.cartButton} >Buy on OpenSea</button></a>;
  // if (!isFinished) button = <button disabled className={styles.cartButton}>{days.toString().padStart(2, '0')}:{hours.toString().padStart(2, '0')}:{minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}</button>;
  if (isKWL) {
    if (!address) button = <button className={styles.cartButton} onClick={() => connectWallet()}>Connect Wallet</button>
    else if (needsWhitelisting) button = <button className={styles.cartButton} disabled>Not on whitelist</button>;
    else if (pool.hasMinted) button = <button className={styles.cartButton} disabled>Already Minted</button>;
    else if (cart[pool.id]) button = <button className={styles.cartButton} onClick={viewCart}>View Cart</button>;
    else button = <button disabled={mintedOut} className={styles.cartButton} onClick={() => addToCart(pool.id)}>Add to Cart</button>;
  }
  // else if (address && needsWhitelisting) button = <button className={styles.cartButton} disabled>Not on whitelist</button>;
  // else if (cart[pool.id]) button = <button className={styles.cartButton} onClick={viewCart}>View Cart</button>;


  return (
    <PoolCard key={pool.id}>
      <div className={styles.info}>
        <div style={{ height: '103px'}}>
          <h5>{pool.name}</h5>
          {pool.description}
          {pool.requiresWhitelist && onWhitelist && <ul><li style={{ color: '#45B36B' }}>You are whitelisted</li></ul>}
        </div>
        
        <div>
          <div className={styles.value}>
            {priceSection}
          </div>
          <div className={styles.availabilityRow}>
            {status}
            {!isKWL && <div>{0}<span className={styles.grey}>/{pool.supply}</span></div>}
          </div>
          {button}
          
          
        </div>
      </div>
    </PoolCard>
  )
}

