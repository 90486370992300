import { useEffect, useState } from "react";
import s from "./page_preview.module.scss";
import cn from "classnames";

const PagePreview = (props) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(props.page);
  const [height, setHeight] = useState(200);
  const [width, setWidth] = useState(200);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  useEffect(() => {
    window.addEventListener(
      "resize",
      function (e) {
        setSize(e.currentTarget);
      },
      true
    );
    setSize(window);
  }, []);

  const setSize = function (window) {
    let w = window.innerWidth;
    let h = window.innerHeight;

    if (w < 660) {
      setHeight(140);
      setWidth(140);
    } else {
      setHeight(200);
      setWidth(200);
    }
  };

  const formatPage = (page) => {
    page = page + "";
    if (page.length === 1) {
      page = "00" + page;
    } else if (page.length === 2) {
      page = "0" + page;
    } else {
      page = page;
    }
    return page;
  };

  return (
    <div className={s.container} onClick={() => props.onClick(props.page)}>
      <div className={s.book_holder}>
        <img
          src={
            require(`../../../../../../assets/book/pages-73a8ab8503e3d6011e54e2a0e1f843fb/page-${formatPage(
              props.page
            )}.png`).default
          }
        />
      </div>
    </div>
  );
};

export default PagePreview;
