import styles from "./Footer.module.scss";
import MainWrapper from "../UI/MainWrapper/MainWrapper";
import Telegram from "../../assets/socials/Telegram";
import Instagram from "../../assets/socials/Instagram";
import Twitter from "../../assets/socials/Twitter";
import Discord from "../../assets/socials/Discord";

const Footer = () => {
  return (
    <footer>
      <MainWrapper>
        <div className={styles.footer}>
          <div className={styles.left}>
            <p className={styles.name}>Jordi Molla</p>
            <p className={styles.small}>Copyright © 2021</p>
          </div>
          <div className={styles.right}>
            <ul>
              <li><a href="https://t.me/rainicornchat" className={styles.button}><Telegram /></a></li>
              <li><a href="https://www.instagram.com/krewstudios" className={styles.button}><Instagram /></a></li>
              <li><a href="https://twitter.com/krewstudios_" className={styles.button}><Twitter /></a></li>
              <li><a href="https://discord.gg/XUHdTyD9MP" className={styles.button}><Discord /></a></li>
            </ul>
          </div>
        </div>
      </MainWrapper>
    </footer>
  );
};

export default Footer;
