import s from "./footer.module.scss";
import Telegram from "../../../../assets/socials/Telegram";
import Instagram from "../../../../assets/socials/Instagram";
import Twitter from "../../../../assets/socials/Twitter";
import Discord from "../../../../assets/socials/Discord";

const Footer = () => {
  return (
    <div className={s.container}>
      <div className={s.desc}>
        <h1>JORDI MOLLA</h1>
        <p>{`Copyright © 2022 Jordimoalla.com`}</p>
      </div>
      <div className={s.links}>
        <ul>
          <li>
            <a href="https://t.me/rainicornchat" className={s.button}>
              <Telegram />
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/krewstudios"
              className={s.button}
            >
              <Instagram />
            </a>
          </li>
          <li>
            <a
              href="https://twitter.com/krewstudios_"
              className={s.button}
            >
              <Twitter />
            </a>
          </li>
          <li>
            <a href="https://discord.gg/XUHdTyD9MP" className={s.button}>
              <Discord />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Footer;
