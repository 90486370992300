const Close = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.565354 0.565598C0.955878 0.175074 1.58904 0.175074 1.97957 0.565598L9.67246 8.25849L17.3654 0.565598C17.7559 0.175074 18.389 0.175074 18.7796 0.565598C19.1701 0.956123 19.1701 1.58929 18.7796 1.97981L11.0867 9.67271L18.7796 17.3656C19.1701 17.7561 19.1701 18.3893 18.7796 18.7798C18.389 19.1703 17.7559 19.1703 17.3654 18.7798L9.67246 11.0869L1.97957 18.7798C1.58904 19.1703 0.955878 19.1703 0.565354 18.7798C0.17483 18.3893 0.17483 17.7561 0.565354 17.3656L8.25825 9.67271L0.565354 1.97981C0.17483 1.58929 0.17483 0.956123 0.565354 0.565598Z"
        fill="white"
      />
    </svg>
  );
};

export default Close;
