const Telegram = () => {
  return (
    <svg
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.9946 0.233099C19.6816 0.246716 19.3943 0.336752 19.1419 0.436224C18.9088 0.528046 18.0715 0.879955 16.7383 1.43915C15.4051 1.99835 13.6384 2.73978 11.7554 3.53176C7.98925 5.11573 3.75709 6.8975 1.58854 7.81008C1.51407 7.84111 1.21163 7.9337 0.877604 8.18883C0.5428 8.44455 0.170898 8.99953 0.170898 9.60647C0.170898 10.0961 0.415313 10.5947 0.710449 10.8802C1.00558 11.1657 1.3047 11.2992 1.55469 11.3986V11.3965C2.39972 11.7332 4.92325 12.743 5.52409 12.9834C5.73721 13.6221 6.65214 16.3562 6.86979 17.0438H6.86768C7.02152 17.5305 7.17148 17.8476 7.37972 18.1123C7.48384 18.2447 7.60813 18.3646 7.76058 18.4593C7.81907 18.4957 7.88384 18.5239 7.94889 18.5503C7.95796 18.5542 7.9673 18.5551 7.9764 18.5588L7.95101 18.5524C7.96987 18.56 7.9868 18.5712 8.00602 18.5778C8.03735 18.5886 8.05906 18.5883 8.10124 18.5969C8.24966 18.6425 8.39781 18.673 8.53499 18.673C9.1239 18.673 9.48502 18.3535 9.48502 18.3535L9.5083 18.3366L12.0516 16.1848L15.1662 19.0666C15.2231 19.1469 15.6687 19.75 16.7002 19.75C17.3157 19.75 17.8023 19.4454 18.1136 19.1258C18.4249 18.8063 18.6189 18.4793 18.7061 18.0362L18.7082 18.034C18.7777 17.6763 21.7593 2.70445 21.7593 2.70445L21.7529 2.72984C21.8457 2.31564 21.8731 1.91507 21.7635 1.50686C21.6539 1.09865 21.358 0.700286 21.0018 0.491237C20.6455 0.282187 20.3077 0.219481 19.9946 0.233099ZM19.5672 2.60501C19.4494 3.19654 16.7785 16.6147 16.6134 17.4564L12.1151 13.2945L9.07454 15.8653L9.91667 12.5729C9.91667 12.5729 15.7261 6.69246 16.076 6.35012C16.3577 6.07604 16.4167 5.97996 16.4167 5.88463C16.4167 5.75788 16.3514 5.66669 16.2008 5.66669C16.0654 5.66669 15.8815 5.79646 15.784 5.85712C14.5451 6.62954 9.26806 9.63677 6.67301 11.113C6.51684 11.0504 4.17218 10.1089 2.90885 9.60435C5.15597 8.65861 9.00792 7.03798 12.5954 5.52916C14.4781 4.73731 16.2442 3.99522 17.5762 3.43655C18.7005 2.96497 19.287 2.72079 19.5672 2.60501ZM16.5817 17.6109H16.5838C16.5838 17.6111 16.5818 17.6169 16.5817 17.6172C16.5831 17.6102 16.5807 17.6163 16.5817 17.6109Z"
        fill="#707482"
      />
    </svg>
  );
};

export default Telegram;
