import styles from "./HomePage.module.scss";
import MainWrapper from "../../components/UI/MainWrapper/MainWrapper";
import { useEffect, useState, useRef } from "react";
import { gsap } from "gsap";
import MaskCarousel from "../../components/MaskCarousel/MaskCarousel";
import whitelist from "../../data/whitelist.json";
import KrewVideo from "../../assets/krew_studios.mp4";
import KrewVideoCover from "../../assets/krew_studios_cover.jpg";
import KrewGalleryVideo from "../../assets/gallery.mp4";
import MaskUtility from "../../assets/utility.jpg";
import KrewGalleryVideoCover from "../../assets/gallery_cover.jpg";
import Chevron from "../../assets/Chevron";
import Play from "../../assets/Play";
import Mute from "../../assets/Mute";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import maskA from "../../assets/mask_horizontal/Mask_Array_Group_V2_A.png";
import maskAA from "../../assets/mask_horizontal/Mask_Array_Group_V2_AA.png";
import maskAAA from "../../assets/mask_horizontal/Mask_Array_Group_V2_AAA.png";
import maskB from "../../assets/mask_horizontal/Mask_Array_Group_V2_B.png";
import maskBB from "../../assets/mask_horizontal/Mask_Array_Group_V2_BB.png";
import maskBBB from "../../assets/mask_horizontal/Mask_Array_Group_V2_BBB.png";
import maskC from "../../assets/mask_horizontal/Mask_Array_Group_V2_C.png";
import maskCC from "../../assets/mask_horizontal/Mask_Array_Group_V2_CC.png";
import maskCCC from "../../assets/mask_horizontal/Mask_Array_Group_V2_CCC.png";

import MaskFront1 from "../../assets/front_facing_masks/Mask_Still_1080_A_V1.png";
import MaskFront2 from "../../assets/front_facing_masks/Mask_Still_1080_AA_V1.png";
import MaskFront3 from "../../assets/front_facing_masks/Mask_Still_1080_AAA_V1.png";
import MaskFront4 from "../../assets/front_facing_masks/Mask_Still_1080_B_V1.png";
import MaskFront5 from "../../assets/front_facing_masks/Mask_Still_1080_BB_V1.png";
import MaskFront6 from "../../assets/front_facing_masks/Mask_Still_1080_BBB_V1.png";
import MaskFront7 from "../../assets/front_facing_masks/Mask_Still_1080_C_V1.png";
import MaskFront8 from "../../assets/front_facing_masks/Mask_Still_1080_CC_V1.png";
import MaskFront9 from "../../assets/front_facing_masks/Mask_Still_1080_CCC_V1.png";

import backgroundPanels from "../../assets/background_panels.png";
import backgroundDots from "../../assets/background_dot.png";
import PoolUI from "../../components/PoolUI/PoolUI";
import BookLink from "./sub-components/BookLink";
import DualJordiLink from "./sub-components/DualJordiLink";

import masks_gallery_img from "../../assets/masks_world/masks_gallery.png";
import mask_runner_img from "../../assets/masks_world/mask_runner.png";
import mask_world_img from "../../assets/masks_world/mask_world.png";

const Homepage = (props) => {
  gsap.registerPlugin(ScrollTrigger);

  const el = useRef();
  const q = gsap.utils.selector(el);
  const tl = useRef();
  const videoRef = useRef();
  const bannerVideoRef = useRef();
  const [playing, setPlaying] = useState(true);

  // const canPlayThroughHandler = (e) => {
  //   videoRef.current.muted = true
  //   videoRef.current.play()
  // };

  // const canPlayHandler = (e) => {
  //   videoRef.current.muted = true
  // }

  // useEffect(() => {
  //   playing ? bannerVideoRef.current.play() : bannerVideoRef.current.pause();
  //   return () => bannerVideoRef.current.pause();
  // }, [playing]);

  // function togglePlay() {
  //   setPlaying(s => !s);
  // }

  // function videoEnd() {
  //   setPlaying(s => false);
  // }

  let masks_world_data = [
    {
      disabled: false,
      img: masks_gallery_img,
      title: "Mask Gallery",
      button: "PLAY NOW",
      link: "https://krewstudios.com/masksgallery/",
      show_badge: false,
      badge: "",
    },
    {
      disabled: false,
      img: mask_runner_img,
      title: "Mask Runner",
      button: "PLAY NOW",
      link: "https://krewstudios.com/runner/",
      show_badge: true,
      badge: "Arcade game",
    },
    {
      disabled: true,
      img: mask_world_img,
      title: "Mask World",
      button: "COMING SOON",
      link: "#",
      show_badge: false,
      badge: "Arcade game",
    },
  ];

  useEffect(() => {
    tl.current = gsap.timeline({ repeat: -1, delay: 0 });

    // Intro
    tl.current.set(q(".mask[n='1']"), {
      opacity: 1,
      x: "-25%",
      scaleX: 1,
      scaleY: 1,
      filter: "blur(0px)",
    });

    var max_size = 9;

    function calculate_num(i, num, max_size) {
      if (i + num > max_size) {
        return i + num - max_size;
      } else {
        return i + num;
      }
    }

    for (var i = 1; i <= max_size; i++) {
      tl.current.fromTo(
        q('.mask[n="' + i + '"]'),
        { opacity: 1 },
        { opacity: 0, duration: 1 }
      );
      tl.current.set(q('.mask[n="' + calculate_num(i, 1, max_size) + '"]'), {
        css: { zIndex: 3 },
      });
      tl.current.set(q('.mask[n="' + calculate_num(i, 2, max_size) + '"]'), {
        css: { zIndex: 2 },
      });
      tl.current.set(q('.mask[n="' + calculate_num(i, 3, max_size) + '"]'), {
        css: { zIndex: 1 },
      });
      tl.current.set(q('.mask[n="' + calculate_num(i, 4, max_size) + '"]'), {
        opacity: 0,
      });
      tl.current.set(q('.mask[n="' + calculate_num(i, 5, max_size) + '"]'), {
        opacity: 0,
      });
      tl.current.set(q('.mask[n="' + calculate_num(i, 6, max_size) + '"]'), {
        opacity: 0,
      });
      tl.current.set(q('.mask[n="' + calculate_num(i, 7, max_size) + '"]'), {
        opacity: 0,
      });
      tl.current.set(q('.mask[n="' + calculate_num(i, 8, max_size) + '"]'), {
        opacity: 0,
      });
      tl.current.set(q('.mask[n="' + calculate_num(i, 9, max_size) + '"]'), {
        opacity: 0,
      });
      tl.current.fromTo(
        q('.mask[n="' + calculate_num(i, 1, max_size) + '"]'),
        { opacity: 1, x: 0, scaleX: 0.75, scaleY: 0.75, filter: "blur(4px)" },
        {
          opacity: 1,
          x: "-25%",
          scaleX: 1,
          scaleY: 1,
          filter: "blur(0px)",
          duration: 2,
        }
      );
      tl.current.fromTo(
        q('.mask[n="' + calculate_num(i, 2, max_size) + '"]'),
        {
          opacity: 1,
          x: "25%",
          scaleX: 0.5,
          scaleY: 0.5,
          filter: "blur(12px)",
        },
        {
          opacity: 1,
          x: 0,
          scaleX: 0.75,
          scaleY: 0.75,
          filter: "blur(4px)",
          duration: 2,
        },
        "<"
      );
      tl.current.fromTo(
        q('.mask[n="' + calculate_num(i, 3, max_size) + '"]'),
        { opacity: 0, x: "40%", scaleX: 0, scaleY: 0, filter: "blur(12px)" },
        {
          opacity: 1,
          x: "25%",
          scaleX: 0.5,
          scaleY: 0.5,
          filter: "blur(12px)",
          duration: 2,
        },
        "<"
      );
    }
  }, []);

  useEffect(() => {
    gsap.fromTo(
      q("#mask-1"),
      {
        y: "-50%",
      },
      {
        y: "0",
        duration: 1,
        scrollTrigger: {
          trigger: q("#behind-mask-section"),
          start: "top bottom",
          markers: false,
          scrub: true,
        },
      }
    );
    gsap.fromTo(
      q("#mask-9"),
      {
        y: "-50%",
      },
      {
        y: "0",
        duration: 1,
        scrollTrigger: {
          trigger: q("#behind-mask-section"),
          start: "top bottom",
          markers: false,
          scrub: true,
        },
      }
    );
    gsap.fromTo(
      q("#mask-2"),
      {
        y: "-40%",
      },
      {
        y: "0",
        duration: 1,
        scrollTrigger: {
          trigger: q("#behind-mask-section"),
          start: "top bottom",
          markers: false,
          scrub: true,
        },
      }
    );
    gsap.fromTo(
      q("#mask-8"),
      {
        y: "-40%",
      },
      {
        y: "0",
        duration: 1,
        scrollTrigger: {
          trigger: q("#behind-mask-section"),
          start: "top bottom",
          markers: false,
          scrub: true,
        },
      }
    );
    gsap.fromTo(
      q("#mask-3"),
      {
        y: "-30%",
      },
      {
        y: "0",
        duration: 1,
        scrollTrigger: {
          trigger: q("#behind-mask-section"),
          start: "top bottom",
          markers: false,
          scrub: true,
        },
      }
    );
    gsap.fromTo(
      q("#mask-7"),
      {
        y: "-30%",
      },
      {
        y: "0",
        duration: 1,
        scrollTrigger: {
          trigger: q("#behind-mask-section"),
          start: "top bottom",
          markers: false,
          scrub: true,
        },
      }
    );
    gsap.fromTo(
      q("#mask-4"),
      {
        y: "-20%",
      },
      {
        y: "0",
        duration: 1,
        scrollTrigger: {
          trigger: q("#behind-mask-section"),
          start: "top bottom",
          markers: false,
          scrub: true,
        },
      }
    );
    gsap.fromTo(
      q("#mask-6"),
      {
        y: "-20%",
      },
      {
        y: "0",
        duration: 1,
        scrollTrigger: {
          trigger: q("#behind-mask-section"),
          start: "top bottom",
          markers: false,
          scrub: true,
        },
      }
    );
    gsap.fromTo(
      q("#mask-5"),
      {
        y: "0%",
      },
      {
        y: "0",
        duration: 1,
        scrollTrigger: {
          trigger: q("#behind-mask-section"),
          start: "top bottom",
          markers: false,
          scrub: true,
        },
      }
    );
  }, []);

  const accountAddress = props.wallet?.accountAddress?.toLowerCase();

  const whiteListData = whitelist[accountAddress];
  const onWhitelist = !!whiteListData;

  return (
    <div ref={el}>
      <section id="home-section" className={styles.headerBanner}>
        <MainWrapper>
          <div className={styles.container}>
            <div className={styles.topContainer}>
              <h1 className={styles.textCenter}>Jordi Molla NFT Collection</h1>
              <p className={styles.textCenter}>
                He is an actor, but he’d go as far as describing everyone as an
                actor. We’re all hiding behind one. It’s not that we think being
                our true self is a sin or shameful, it’s just that there exists
                a different version of ourselves in each person's mind that we
                encounter.Human beings are far too complex, and that's where the
                beauty comes from. Even the simplest of things have a thousand
                faces.
              </p>
            </div>
            <div className={styles.videoGallery}>
              {/* <div className={`${styles.buttonPlay} ${playing ? styles.activePlaying : ""}`}>
                {playing ?
                  <button onClick={() => togglePlay()}><Mute /></button>
                  :
                  <button onClick={() => togglePlay()}><Play /></button>
                }
              </div> */}
              <video
                //ref={bannerVideoRef}
                src={KrewGalleryVideo}
                playsInline
                autoPlay
                loop
                muted
                //onEnded={() => videoEnd()}
                poster={KrewGalleryVideoCover}
              >
                <source src="KrewVideo" type="video/mp4" />
                Your browser does not support HTML5 video.
              </video>
            </div>
          </div>
        </MainWrapper>
      </section>

      <section id="about-section" className={styles.aboutProject}>
        <MainWrapper>
          <div className={styles.twoColumn}>
            <div className={styles.left}>
              <img className={styles.backgroundPanels} src={backgroundPanels} />
              <img className={styles.backgroundDots} src={backgroundDots} />
              <h1>About Project</h1>
              <p>
                MASKS are a collection of unique collectable generative masks
                inspired by Jordi Mollas Original 100 physical hand painted
                artwork. By Teaming up with Krew Studios we have been able to
                digitize and bring the mask into the metaverse. The Mask NFT’s
                will live on the Ethereum blockchain. The Limited supply will be
                revealed closer to the Launch in early January.
              </p>
            </div>
            <div className={styles.right}>
              <div className={styles.maskAnimation}>
                <div className={styles.maskArray}>
                  <div className={`${styles.mask} mask`} n="1">
                    <img src={MaskFront1} />
                  </div>
                  <div className={`${styles.mask} mask`} n="2">
                    <img src={MaskFront2} />
                  </div>
                  <div className={`${styles.mask} mask`} n="3">
                    <img src={MaskFront3} />
                  </div>
                  <div className={`${styles.mask} mask`} n="4">
                    <img src={MaskFront4} />
                  </div>
                  <div className={`${styles.mask} mask`} n="5">
                    <img src={MaskFront5} />
                  </div>
                  <div className={`${styles.mask} mask`} n="6">
                    <img src={MaskFront6} />
                  </div>
                  <div className={`${styles.mask} mask`} n="7">
                    <img src={MaskFront7} />
                  </div>
                  <div className={`${styles.mask} mask`} n="8">
                    <img src={MaskFront8} />
                  </div>
                  <div className={`${styles.mask} mask`} n="9">
                    <img src={MaskFront9} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </MainWrapper>
      </section>

      <section id="behind-mask-section" className={styles.behindMask}>
        <MainWrapper>
          <h1 className={`${styles.textCenter} ${styles.outline}`}>
            <span className={styles.desktop}>
              WHO IS WHO? WHO IS BEHIND THE MASK?
            </span>
            <span className={styles.mobile}>BEHIND THE MASK</span>
          </h1>
          <div className={styles.masks}>
            <div className={styles.container}>
              <img
                style={{ zIndex: "1" }}
                id="mask-1"
                src={maskCCC}
                alt="mask"
              />
              <img style={{ zIndex: "2" }} id="mask-2" src={maskC} alt="mask" />
              <img
                style={{ zIndex: "3" }}
                id="mask-3"
                src={maskBB}
                alt="mask"
              />
              <img
                style={{ zIndex: "4" }}
                id="mask-4"
                src={maskAAA}
                alt="mask"
              />
              <img style={{ zIndex: "5" }} id="mask-5" src={maskA} alt="mask" />
              <img
                style={{ zIndex: "4" }}
                id="mask-6"
                src={maskAA}
                alt="mask"
              />
              <img style={{ zIndex: "3" }} id="mask-7" src={maskB} alt="mask" />
              <img
                style={{ zIndex: "2" }}
                id="mask-8"
                src={maskBBB}
                alt="mask"
              />
              <img
                style={{ zIndex: "1" }}
                id="mask-9"
                src={maskCC}
                alt="mask"
              />
            </div>
          </div>
        </MainWrapper>
      </section>

      <section id="video-section" className={styles.videoSection}>
        <MainWrapper>
          <div className={`${styles.twoColumn} ${styles.topAligned}`}>
            <div className={styles.left}>
              <img className={styles.backgroundPanels} src={backgroundPanels} />
              <img className={styles.backgroundDots} src={backgroundDots} />
              <h1>Who is behind the masks?</h1>
            </div>
            <div className={styles.right}>
              <div className={styles.video}>
                <iframe
                  src="https://www.youtube.com/embed/CNU5e5EBOJU"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
        </MainWrapper>
      </section>

      <section id="outsider-links" className={styles.videoSection}>
        <MainWrapper>
          <BookLink />
          <DualJordiLink />
        </MainWrapper>
      </section>
      {/* <section id='mask-world-section' className={styles.maskWorldSection}>
      <MainWrapper>
          <div className={styles.headingButton}>
            <h1 className={styles.desktop}>Masks World</h1>
            <h1 className={styles.mobile}>Masks World</h1>
          </div>
          <div className={styles.list}>
            {masks_world_data.map((row, i) => {
              return (
                <div
                  className={[
                    styles.item,
                    row.disabled ? styles.disabled : "",
                  ].join(" ")}
                  key={i}
                >
                  <div className={styles.img_holder}>
                    <img src={row.img} alt={row.title} />
                    {row.show_badge ? <span>{row.badge}</span> : null}
                  </div>
                  <p>{row.title}</p>
                  <a
                    href={row.link}
                    onClick={(e) => (row.disabled ? e.preventDefault() : null)}
                    target='_blank'
                    className={styles.button}
                  >
                    <h1>{row.button}</h1>
                  </a>
                </div>
              );
            })}
          </div>
        </MainWrapper>
      </section> */}

      <section id="collection-section" className={styles.masksSection}>
        <MainWrapper>
          <div className={styles.headingButton}>
            <h1 className={styles.desktop}>The Masks</h1>
            <h1 className={styles.mobile}>The Masks</h1>
            {/* <a className={styles.linkButton}>
              <span>Check all</span>
              <Chevron />
            </a> */}
          </div>
          <img className={styles.backgroundDots} src={backgroundDots} />
          {/* <MaskCarousel /> */}

          <PoolUI wallet={props.wallet} />

          {/* <a className={`${styles.linkButton} ${styles.mobile} ${styles.textCenter}`}>
            <span>Check all</span>
            <Chevron />
          </a> */}
        </MainWrapper>
      </section>

      <section id="utility-section" className={styles.aboutProject}>
        <MainWrapper>
          <div className={styles.twoColumn}>
            <div className={styles.left}>
              <img className={styles.backgroundPanels} src={backgroundPanels} />
              <img className={styles.backgroundDots} src={backgroundDots} />
              <h1>Roadmap &amp; Utility</h1>
              <p>
                <ul>
                  <li>
                    • Access to any Paul Fisher Gallery, Jordi Mollá pop-ups,
                    future events and so on.
                  </li>
                  <br />
                  <li>• Metaverse wearable / accessory</li>
                  <br />
                  <li>
                    • Access to the Krew Studios Metaverse and MASKS digital
                    Gallery.
                  </li>
                  <br />
                  <li>
                    • MASKS holders will get special access to all of Jordi's
                    upcoming drops and Metaverse events, stay Tuned.
                  </li>
                  <br />
                  <li>
                    • A number of holders will randomly selected and airdropped
                    Jordi's upcoming NFT book.
                  </li>
                </ul>
              </p>
            </div>
            <div className={styles.right}>
              <img src={MaskUtility} id="roadmapUtilityImg"></img>
            </div>
          </div>
        </MainWrapper>
      </section>

      <section id="krew-section" className={styles.krewVideo}>
        <MainWrapper>
          <div className={styles.twoColumn}>
            <div className={styles.left}>
              <video
                ref={videoRef}
                src={KrewVideo}
                playsInline
                loop
                autoPlay
                muted
                poster={KrewVideoCover}
                // onCanPlay={canPlayHandler}
                // onCanPlayThrough={canPlayThroughHandler}
              >
                <source src="KrewVideo" type="video/mp4" />
                Your browser does not support HTML5 video.
              </video>
            </div>
            <div className={styles.right}>
              <img className={styles.backgroundPanels} src={backgroundPanels} />
              <img className={styles.backgroundDots} src={backgroundDots} />
              <h1>Krew Studios</h1>
              <p>
                The Krew is built up of a unique collective of individuals
                bridging the gap between physical and digital art. Using the
                right technology, strategy and creative direction, Krew Studios
                implements bringing visions and ideas to life both in the real
                world and onto the blockchain.
              </p>
              <br />
              <br />
              <a
                className={`${styles.linkButton} ${styles.white}`}
                href="https://www.krewstudios.com/"
                target="_blank"
                rel="noreferrer"
              >
                <span>Learn More</span>
                <Chevron />
              </a>
            </div>
          </div>
        </MainWrapper>
      </section>
    </div>
  );
};

export default Homepage;
