import "./App.css";
import Layout from "./components/Layout/Layout";
import Header from "./components/Header/Header";
import Homepage from "./pages/Homepage/Homepage";
import Auction from "./pages/Auction/Auction";
import Footer from "./components/Footer/Footer";
import { useWallet } from "./components/AuctionUI/api";
import { ToastContainer } from "react-toastify";
import { Routes, Route, Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.min.css";
import Book from "./pages/Book";
import Masks from "./pages/104Masks";

function App() {
  const wallet = useWallet();
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Layout>
            <Header type="home" wallet={wallet} />
            <Homepage wallet={wallet} />
            <Footer />
            <ToastContainer theme="dark" />
          </Layout>
        }
      />
      <Route
        path="/auction"
        element={
          <Layout>
            <Header type="auction" prefix={"/"} wallet={wallet} />
            <Auction wallet={wallet} />
            <Footer />
            <ToastContainer theme="dark" />
          </Layout>
        }
      />
      <Route
        path="/test"
        element={
          <Layout>
            <p>Hello world!</p>
          </Layout>
        }
      />
      <Route
        path="/book"
        element={
          <Layout>
            <Book />
          </Layout>
        }
      />
      <Route
        path="/104_masks"
        element={
          <Layout>
            <Header type="home" prefix="/" wallet={wallet} />
            <Masks />
            <Footer />
          </Layout>
        }
      />
    </Routes>
  );
}

export default App;
