import React from "react"
import styles from "./Auction.module.scss";
import MainWrapper from "../../components/UI/MainWrapper/MainWrapper";
import Chevron from "../../assets/Chevron";
import { useEffect, useState, useRef } from "react";
import { useKeenSlider } from "keen-slider/react";
import RoadmapCarouselContent from "../../components/RoadmapCarousel/RoadmapCarouselContent";
import backgroundPanels from "../../assets/background_panels.png";
import backgroundDots from "../../assets/background_dot.png";
import bannerHeading from "../../assets/auction/banner_heading.png";
import bannerArtwork from "../../assets/auction/banner_artwork.png";
import aboutImage from "../../assets/auction/about_image.png";
import paintingFlowers from "../../assets/auction/painting_flowers.png";
import Chevon from "../../assets/Chevron";
import Physical from "../../assets/auction/icon/physical.svg";
import Checkmark from "../../assets/auction/icon/checkmark.svg";
import Money from "../../assets/auction/icon/money.svg";
import ArrowTwoLeft from "../../assets/ArrowTwoLeft";
import ArrowTwoRight from "../../assets/ArrowTwoRight";
import collectorsBenefits from "../../assets/auction/collectors_benefits.png";
import KrewVideo from "../../assets/krew.mp4";
import KrewVideoCover from "../../assets/krew_cover.jpg";
import Play from "../../assets/PlayVideo";
import Mute from "../../assets/PauseVideo";
import AuctionUI from "../../components/AuctionUI/AuctionUI";
import Countdown from "../../components/AuctionUI/Countdown";
import { AUCTION_START_TIME, SHOW_AUCTION_UI, useCountdown } from "../../components/AuctionUI/api";

const maskData = [
  {
    icon: Physical,
    name: "Redeem Physical",
    desc: "The winner of this piece is entitled to claim the physical signed and painted by Jordi Molla."
  },
  {
    icon: Checkmark,
    name: "Guaranteed Whitelist",
    desc: "The winner will automatically gain 5 whitelist spots for the “Masks” Collection by Jordi Molla."
  },
  {
    icon: Money,
    name: "Art Basel Access",
    desc: "The winner will be able to join the Art Basel NFT Event hosted by Jordi Molla x Krew Studios."
  },
  { // A third one is placed so the slider can show 3 at a time
    icon: Physical,
    name: "Redeem Physical",
    desc: "The winner of this piece is entitled to claim the physical signed and painted by Jordi Molla."
  },
  {
    icon: Checkmark,
    name: "Guaranteed Whitelist",
    desc: "The winner will automatically gain 5 whitelist spots for the “Masks” Collection by Jordi Molla."
  },
  {
    icon: Money,
    name: "Art Basel Access",
    desc: "The winner will be able to join the Art Basel NFT Event hosted by Jordi Molla x Krew Studios."
  },
];

const Auction = (props) => {
  const [pause, setPause] = React.useState(false);
  const timer = React.useRef();
  const onSlider = React.useRef(null);
  const [currentSlide, setCurrentSlider] = useState(0);
  const { isFinished } = useCountdown(AUCTION_START_TIME)
  const [sliderRef, slider] = useKeenSlider({
    slidesPerView: 3,
    duration: 1000,
    centered: false,
    loop: true,
    spacing: 0,
    controls: true,
    spacing: 24,
    breakpoints: {
      '(max-width: 960px)': {
        slidesPerView: 3,
      },
      '(max-width: 780px)': {
        slidesPerView: 3,
        centered: true,
      },
      '(max-width: 480px)': {
        slidesPerView: 1,
      },
    },
    slideChanged(slide) {
      setCurrentSlider(slide.details().relativeSlide);
    },
  });
  const bannerVideoRef = useRef();
  const [playing, setPlaying] = useState(false);

  useEffect(() => {
    playing ? bannerVideoRef.current.play() : bannerVideoRef.current.pause();
    return () => bannerVideoRef.current.pause();
  }, [playing]);

  function togglePlay() {
    setPlaying(s => !s);
  }


  function videoEnd() {
    setPlaying(s => false);
  }

  React.useEffect(() => {
    sliderRef.current.addEventListener("mouseover", () => {
      setPause(true)
    })
    sliderRef.current.addEventListener("mouseout", () => {
      setPause(false)
    })
  }, [sliderRef])

  React.useEffect(() => {
    onSlider.current.addEventListener("mouseover", () => {
      setPause(true)
    })
    onSlider.current.addEventListener("mouseout", () => {
      setPause(false)
    })
  }, [onSlider])

  React.useEffect(() => {
    timer.current = setInterval(() => {
      if (!pause && slider) {
        slider.next()
      }
    }, 8000)
    return () => {
      clearInterval(timer.current)
    }
  }, [pause, slider])

  return (
    <div>
      <section id="home-section" className={styles.headerBanner}>
        <MainWrapper style={{ position: 'relative'}}>
          <div className={styles.twoColumn}>
            <div className={styles.left}>
              <img src={bannerHeading} />
            </div>
            <div className={styles.right}>
              <img src={bannerArtwork} />
            </div>
            
          </div>
          <Countdown />
        </MainWrapper>
      </section>
      {isFinished && SHOW_AUCTION_UI &&
        <section>
          <AuctionUI wallet={props.wallet}/>
        </section>
      }
      

      <section id="about-section" className={styles.aboutProject}>
        <MainWrapper>
          <div className={styles.twoColumn}>
            <div className={styles.left}>
              <img className={styles.backgroundPanels} src={backgroundPanels} />
              <img className={styles.backgroundDots} src={backgroundDots} />
              <h1>About</h1>
              <p>
                Jordi's “I will flood you with my flowers” is a special piece, the winner will have the right to claim the physical signed and painted by Jordi himself. They will also be whitelisted for his “Masks” collection, and receive tickets to the Jordi Molla x KREW studios art Basel NFT event.
              </p>
            </div>
            <div className={styles.right}>
              <img src={aboutImage} />
            </div>
          </div>
        </MainWrapper>
      </section>

      <section id="jordi-section" className={styles.jordiSection}>
        <MainWrapper>
          <div className={`${styles.twoColumn} ${styles.topAligned}`}>
            <div className={styles.left}>
              <img className={styles.backgroundPanels} src={backgroundPanels} />
              <img className={styles.backgroundDots} src={backgroundDots} />
              <h1>Who is Jordi Mollà?</h1>
              <p>
                Jordi is a self-trained painter and successful actor, for whom painting is an act of spontaneity. His works often surprise the viewer both with his ability to manage the media and with his power to express himself on canvas or paper
              </p>
            </div>
            <div className={styles.right}>
              <div className={styles.video}>
                <div className={`${styles.buttonPlay} ${playing ? styles.activePlaying : ""}`}>
                  {playing ?
                    <button onClick={() => togglePlay()}><Mute /></button>
                    :
                    <button onClick={() => togglePlay()}><Play /></button>
                  }
                </div>
                <video
                  ref={bannerVideoRef}
                  src={KrewVideo}
                  playsInline
                  onEnded={() => videoEnd()}
                  poster={KrewVideoCover}
                >
                  <source src="KrewVideo" type="video/mp4" />
                  Your browser does not support HTML5 video.
                </video>
              </div>
            </div>
          </div>
        </MainWrapper>
      </section>

      <section id="roadmap-section" className={styles.roadmapSection}>
        <MainWrapper>
          <div className={`${styles.twoColumn} ${styles.topAligned}`}>
            <div className={styles.left}>
              <h1>Roadmap</h1>
              <p>
                Jordi’s “I will flood you with flowers” is a special piece inspired by the nature of Marbella. An outburst of colors and texture influenced by the artist's experiences on Costa Del Sol.
              </p>
              <img src={collectorsBenefits} />
              {slider && (
                <div className={`${styles["btn-container"]} ${styles.desktop}`}>
                  <button className={styles["arrow-button"]} onClick={() => slider.prev()}>
                    <ArrowTwoLeft />
                  </button>
                  <button className={styles["arrow-button"]} onClick={() => slider.next()}>
                    <ArrowTwoRight />
                  </button>
                </div>
              )}
            </div>
            <div className={styles.right}>
              {slider && (
                <div className={`${styles.buttonContainer} ${styles.mobile} ${styles.left}`}>
                  <button className={`${styles.btn}`} onClick={() => slider.prev()}>
                    <Chevon />
                  </button>
                </div>
              )}
              <div ref={onSlider} className={styles.carousel}>
                <div className={styles.container}>
                  <div ref={sliderRef} className={`${styles.slider} keen-slider`}>
                    {maskData.map((mask, index) => (
                      <RoadmapCarouselContent
                        key={index}
                        mask={mask}
                        index={index}
                        slider={slider}
                        currentSlide={currentSlide}
                      />
                    ))}
                  </div>
                </div>
              </div>
              {slider && (
                <div className={`${styles.buttonContainer} ${styles.mobile} ${styles.right}`}>
                  <button className={`${styles.btn}`} onClick={() => slider.next()}>
                    <Chevon />
                  </button>
                </div>
              )}
            </div>
            {slider && (
              <div className={styles.centreAlign}>
                <div className={`${styles["btn-container"]} ${styles.mobile}`}>
                  <button className={styles["arrow-button"]} onClick={() => slider.prev()}>
                    <ArrowTwoLeft />
                  </button>
                  <button className={styles["arrow-button"]} onClick={() => slider.next()}>
                    <ArrowTwoRight />
                  </button>
                </div>
              </div>
            )}
          </div>
        </MainWrapper>
      </section>

      <section id="krew-section" className={styles.krewSection}>
        <MainWrapper>
          <div className={styles.twoColumn}>
            <div className={styles.left}>
              <img className={styles.backgroundPanels} src={backgroundPanels} />
              <img className={styles.backgroundDots} src={backgroundDots} />
              <h1>Krew Studios</h1>
              <p>
                The Krew is built up of a unique collective of individuals
                bridging the gap between physical and digital art. Using the
                right technology, strategy and creative direction, Krew Studios
                implements bringing visions and ideas to life both in the real
                world and onto the blockchain.
              </p>
              <br /><br />
              <a className={`${styles.linkButton} ${styles.white}`} href='https://www.krewstudios.com/' target='_blank' rel='noreferrer'>
                <span>Learn More</span>
                <Chevron />
              </a>
            </div>
            <div className={styles.right}>
              <img src={paintingFlowers} />
            </div>
          </div>
        </MainWrapper>
      </section>
    </div>
  );
};

export default Auction;
