import s from "./inside.module.scss";
import Sign_4 from "../../../../assets/book/sign_4.png";
import inside_0 from "../../../../assets/book/inside_0.png";
import inside_1 from "../../../../assets/book/inside_1.png";
import inside_2 from "../../../../assets/book/inside_2.png";
import inside_3 from "../../../../assets/book/inside_3.png";

const Inside = (props) => {
  let slides = [inside_0, inside_1, inside_2, inside_3];
  return (
    <div {...props} className={s.container}>
      <div className={s.content}>
        <div className={s.desc}>
          <h1>Preview of jordi</h1>
          <h1>{`molla’s book`}</h1>
          <img src={Sign_4} alt='sign' />
        </div>
        <div className={s.wrapper}>
          <div className={s.insides}>
            {slides.map((slide, index) => {
              return <img src={slide} key={index} alt='slide' />;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Inside;
