import s from "./book_page.module.scss";
import PagePreview from "./sub-components/page_preview";
import { useState } from "react";
import book from "../../../../assets/book/240d7fde2a215e95bcda481022fb3033.pdf";

const BookPage = (props) => {
  let page_count = 172;
  //   let page_count = 10;
  const [selected_page, setSelectedPage] = useState(null);
  return (
    <div className={s.container}>
      <div class={s.content}>
        <div className={s.info}>
          <h1 className={s.title}>HERE IS YOUR BOOK</h1>
          <div className={s.filter}>
            <input
              type="number"
              placeholder="GO TO PAGE NUMBER"
              min={1}
              max={page_count}
              value={selected_page}
              onChange={(e) =>
                setSelectedPage(
                  e.target.value <= 0
                    ? ""
                    : e.target.value > page_count
                    ? page_count
                    : e.target.value
                )
              }
            />
            <button
              onClick={() => {
                if (selected_page) {
                  props.showPage(selected_page);
                }
              }}
            >
              GO TO PAGE
            </button>
          </div>
        </div>
      </div>
      <div className={s.list}>
        {[...Array(page_count)].map((_, index) => {
          return (
            <PagePreview
              key={index}
              page={index + 1}
              book={book}
              onClick={props.showPage}
            />
          );
        })}
      </div>
    </div>
  );
};

export default BookPage;
