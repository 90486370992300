import s from "./video.module.scss";
import Sign_2 from "../../../../assets/book/sign_2.png";

const Video = (props) => {
  return (
    <div {...props} className={s.container}>
      <div className={s.desc}>
        <div className={s.title}>
          <h1>BOOK VIDEO</h1>
        </div>
        <img src={Sign_2} alt="sign" />
        <div className={s.gap}></div>
      </div>
      <div className={s.video_container}>
        <iframe
          className={s.video_frame}
          src="https://www.youtube.com/embed/9WRJ5wiffjg"
          title="Jordi Molla Book NFT teaser"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe>
      </div>
    </div>
  );
};

export default Video;
