import React, { Suspense, useRef } from 'react'

import { Canvas } from '@react-three/fiber'
import { OrbitControls, Stage } from '@react-three/drei'
import Model from '../MaskCarousel/Model'
import { height, style } from '@mui/system';

import styles from "./PoolUI.module.scss";
import maskAnimation from '../../assets/mask-animation.mp4';
// https://codesandbox.io/s/re-using-gltfs-dix1y
// https://gltf.pmnd.rs/

const  PoolCard = ( { index, children }) => {
  const ref = useRef()

  return (
  <div
    className={styles.slide}
    style={{ margin: '0 10px 0 10px'}}
    key={index} >
      <div className={`${styles.inner}`}>
        <div className={styles.imageContainer} style={{ height: '266px', width: '266px'}} >
        <div className={styles.left}>
          <video
            style={{ width: '100%', borderRadius: '12px'}}
            src={maskAnimation}
            playsInline
            loop
            autoPlay
            muted
            //poster={KrewVideoCover}
            // onCanPlay={canPlayHandler}
            // onCanPlayThrough={canPlayThroughHandler}
          >
            <source src="KrewVideo" type="video/mp4" />
            Your browser does not support HTML5 video.
          </video>
        </div>
        {/* <Canvas shadows dpr={[1, 2]} camera={{ fov: 50 }}>
          <Suspense fallback={null}>
            <Stage controls={ref} preset="rembrandt" intensity={1}  environment="city">
              <Model />
            </Stage>
          </Suspense>
          <OrbitControls ref={ref} autoRotate autoRotateSpeed="10" />
        </Canvas> */}
        </div>
       {children}
      </div>
  </div>
  );
};

export default PoolCard;
