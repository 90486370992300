import { MerkleTree } from "merkletreejs";
import keccak256 from "keccak256";
import ERC721Minter from "../abis/ERC721Minter.json";
import { TxnToast } from "../components/AuctionUI/api";
import ERC721Custom from "../abis/ERC721Custom.json";
import ClaimTracker from "../abis/ClaimTracker.json";
import TestNFT from "../abis/TestNFT.json";
import axios from "axios";
export const buildMerkleTree = (addrs) => {
  const leafNodes = addrs.map((addr) => keccak256(addr));
  const merkleTree = new MerkleTree(leafNodes, keccak256, { sortPairs: true });

  return merkleTree;
};

export const buildProof = (tree, addr) => {
  const leafNode = keccak256(addr);
  return tree.getHexProof(leafNode);
};

const KWL_ADDRESS_BOOK = "0xf0c80889848bEC8C49721f8d57441968708B3515";
//rinkeby
// const KWL_ADDRESS_BOOK = "0x883E13381f22AF6D76065dD212C2cd436282Fbf0";

export const mintBook = async ({
  provider,
  address,
  whitelist,
  toast,
  setClaimed,
}) => {
  let returnError;
  //get contract
  const krewWL = getContract(provider, KWL_ADDRESS_BOOK, ERC721Minter.abi);

  //build merkle proof
  const tree = buildMerkleTree(whitelist.addresses);
  const proof = buildProof(tree, address);

  //send transaction
  let toastId;
  let hash;
  await krewWL.methods
    .mint(proof)
    .send({
      from: address,
    })
    .once("transactionHash", (_hash) => {
      hash = _hash;
      toastId = toast(
        <TxnToast hash={hash} message={"Transaction in progress"} />,
        {
          autoClose: false,
          closeOnClick: false,
        }
      );
    })
    .on("error", (error) => {
      toast.dismiss(toastId);
      returnError = true;
      if (error.code === 4001) {
        return;
      }
      if (hash) {
        toast.error(<TxnToast hash={hash} message={"Transaction failed"} />, {
          autoClose: 10000,
          closeOnClick: false,
        });
      } else {
        toast.error("There was an error sending this transaction", {
          autoClose: 10000,
          closeOnClick: false,
        });
      }
    })
    .then(async () => {
      toast.dismiss(toastId);
      toast(<TxnToast hash={hash} message={"Transaction successful!"} />, {
        autoClose: 5000,
        closeOnClick: false,
      });
      setClaimed(true);
    });
  return returnError;
};

export const onWhitelist = (whitelist, accountAddress) => {
  if (!whitelist || !accountAddress) return false;
  return whitelist.addresses.includes(accountAddress.toLowerCase());
};

export const getContract = (provider, address, abi) => {
  if (!provider) return false;
  return new provider.eth.Contract(abi, address);
};

export const isMinted = async (whitelist, address, provider) => {
  if (!provider || !onWhitelist(whitelist, address)) return false;
  const kwlContract = getContract(provider, KWL_ADDRESS_BOOK, ERC721Minter.abi);

  const tree = buildMerkleTree(whitelist.addresses);
  const proof = buildProof(tree, address);

  let kwlMinted = false;
  if (!!address) {
    try {
      const res = await kwlContract.methods.mint(proof).call({ from: address });
    } catch (error) {
      if (
        !JSON.parse(error.message.slice(37, error.message.length)).originalError
          .message == "execution reverted: Already claimed."
      ) {
        throw new Error("Error in checking mint status");
      } else {
        kwlMinted = true;
      }
    }
  }
  console.log("is minted:", kwlMinted);
  return kwlMinted;
};

export const ownsBook = async (provider, address) => {
  const nftContract = await getContract(
    provider,
    ERC721Custom.address,
    //rinkeby
    // "0x926E454672e29ec29a5e92929a0a47635f4AB740",
    ERC721Custom.abi
  );
  const balance = await nftContract.methods.balanceOf(address).call();
  return balance > 0 ? true : false;
};

//104 masks claim

export const hasClaimed = async (provider, tokenId) => {
  let contract;
  if ((await provider.eth.getChainId()) != 1) {
    //rinkeby
    contract = await getContract(
      provider,
      ClaimTracker.rinkeby_address, //rinkeby
      ClaimTracker.abi
    );
  } else {
    //mainnet
    contract = await getContract(
      provider,
      ClaimTracker.address,
      ClaimTracker.abi
    );
  }
  const claimed = await contract.methods.unclaimed(tokenId).call();
  return !claimed;
};

export const getTokenIds = async (address, chainId) => {
  const network = chainId == 1 ? "eth" : "rinkeby";
  const contractAddress =
    chainId == 1
      ? "0x53b217E1f76925b3AFD4A0d3cE0dD8e11A822846"
      : TestNFT.rinkeby_address;
  let tokenIds;
  try {
  const res = await axios.get(
    `https://krewstudios.com//API/RetriveNFts.php?owner=${address}&contract_address=${contractAddress}&ChainId=${network}`
  );
  console.log('res.data.result', res.data.result);
  tokenIds = res.data.result.map((data) => {
    return data.token_id;
  });
  console.log("tokenIds:", tokenIds);
  return tokenIds;
  }catch(e){
    console.log(e)
  }
};

// export const getTokenIds = async (provider, address) => {
//   let rids;
//   let contract;
//   const chainId = await provider.eth.getChainId();
//   console.log(chainId);
//   if (chainId != 1) {
//     //rinkeby
//     rids = ClaimTracker.testTokenIds;
//     contract = await getContract(
//       provider,
//       TestNFT.rinkeby_address, //rinkeby
//       TestNFT.abi
//     );
//   } else if (chainId == 1) {
//     //mainnet
//     console.log("mainnet");
//     rids = ClaimTracker.tokenIds;
//     contract = await getContract(
//       provider,
//       "0x53b217E1f76925b3AFD4A0d3cE0dD8e11A822846", // mainnet
//       TestNFT.abi
//     );
//   }
//   const ownersPromise = rids.map((id) => {
//     return contract.methods.ownerOf(id).call();
//   });
//   const owners = await Promise.allSettled(ownersPromise);
//   console.log("nft contract:", contract?._address);

//   const tokenIds = owners
//     .map((owner, index) => {
//       if (owner.status == "rejected") return false;
//       else if (owner.value.toLowerCase() == address.toLowerCase()) {
//         return rids[index];
//       } else return false;
//     })
//     .filter((x) => x);

//   return tokenIds;
// };

export const claim = async (
  provider,
  tokenId,
  accountAddress,
  toast,
  update,
  hideModal,
  sendNotifyEmail,
  sendFailedEmail,
  setFailedStatus
) => {
  let contract;
  //get contract
  if ((await provider.eth.getChainId()) != 1) {
    //rinkeby
    contract = await getContract(
      provider,
      ClaimTracker.rinkeby_address,
      ClaimTracker.abi
    );
  } else {
    //mainnet
    contract = await getContract(
      provider,
      ClaimTracker.address,
      ClaimTracker.abi
    );
  }
  let returnError;

  //send transaction
  let toastId;
  let hash;
  await contract.methods
    .claim(tokenId)
    .send({
      from: accountAddress,
      // gasLimit: 100000,
    })
    .once("transactionHash", (_hash) => {
      hash = _hash;
      toastId = toast(
        <TxnToast hash={hash} message={"Transaction in progress"} />,
        {
          autoClose: false,
          closeOnClick: false,
        }
      );
    })
    .on("error", (error) => {
      toast.dismiss(toastId);
      returnError = true;
      if (error.code === 4001) {
        return;
      }
      if (hash) {
        toast.error(<TxnToast hash={hash} message={"Transaction failed"} />, {
          autoClose: 10000,
          closeOnClick: false,
        });
        sendFailedEmail();
        setFailedStatus();
      } else {
        toast.error("There was an error sending this transaction", {
          autoClose: 10000,
          closeOnClick: false,
        });
        sendFailedEmail();
        setFailedStatus();
      }
    })
    .then(async () => {
      toast.dismiss(toastId);
      toast(<TxnToast hash={hash} message={"Transaction successful!"} />, {
        autoClose: 5000,
        closeOnClick: false,
      });
      update(tokenId);
      hideModal();
      sendNotifyEmail();
    });
  return returnError;
};
