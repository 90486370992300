import { Fragment, useState } from "react";
import s from "./claim_modal.module.scss";
import claim_book from "../../../../assets/claim_book.png";
import claimed_img from "../../../../assets/check_green.svg";
import { isMinted } from "../../../../utilities/web3Functions";

const Modal = (props) => {
  // EHSAN
  let [isClaimed, setIsClaimed] = useState(false);
  const wallet = props.wallet;
  const claim = () => {
    if (!isClaimed)
      props.mint({
        provider: wallet.web3,
        whitelist: props.whitelist,
        address: wallet.accountAddress,
        toast: props.toast,
        setClaimed: setIsClaimed,
      });
    // }
  };
  const handleIsClaimed = () => {
    isMinted(props.whitelist, wallet.accountAddress, wallet.web3).then(
      (mint) => {
        if (mint) {
          if (!isClaimed) {
            setIsClaimed(true);
          }
        }
      }
    );
  };
  handleIsClaimed();
  const handleButton = () => {
    if (isClaimed) {
      return (
        <button className={s.close_btn} onClick={props.close}>
          CLOSE
        </button>
      );
    } else {
      return (
        <button className={s.button} onClick={props.close}>
          No Longer Active
        </button>
      );
    }
  };
  return (
    <Fragment>
      {props.show ? (
        <div
          className={s.container}
          id='claim_modal'
          onClick={(e) =>
            e.target.getAttribute("id") == "claim_modal" ? props.close() : null
          }
        >
          <div className={s.card}>
            {isClaimed ? (
              <div className={s.congrats}>
                <h1>Congrats!</h1>
                <p>You've successfully claimed Jordi Molla's Book NFT</p>
              </div>
            ) : (
              <h1>Claim your book</h1>
            )}
            <div className={s.book_container}>
              <img src={claim_book} alt='claim_book' className={s.claim_book} />
            </div>
            <div className={s.claimed_container}>
              {isClaimed ? (
                <img src={claimed_img} alt='claim_book' className={s.claimed} />
              ) : null}
            </div>
            {handleButton()}
          </div>
        </div>
      ) : null}
    </Fragment>
  );
};

export default Modal;
