import s from "./Book.module.scss";
import About from "./sub-components/about";
import Hero from "./sub-components/hero";
import Inside from "./sub-components/inside";
import Navbar from "./sub-components/navbar";
import Video from "./sub-components/video";
import Footer from "./sub-components/footer";
import ClaimModal from "./sub-components/claim_modal";
import BookModal from "./sub-components/book_modal";
import { useWallet } from "../../components/AuctionUI/api";
import { Fragment, useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { mintBook, ownsBook } from "../../utilities/web3Functions";
import whitelist from "../../data/whitelistDigitalArtBook.json";
import { toast } from "react-toastify";
import BookPage from "./sub-components/book_page";

const Book = () => {
  //EHSAN
  let [showClaim, setShowClaim] = useState(false);
  let [showBook, setShowBook] = useState(false);
  let [showBookPage, setShowBookPage] = useState(false);
  let [have_book, setHaveBook] = useState(false);
  let [book_page, setBookPage] = useState(null);
  const wallet = useWallet();

  useEffect(() => {
    document.onkeydown = function (e) {
      if (e.keyCode == 123) {
        return false;
      }
      if (e.ctrlKey && e.keyCode == "E".charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.shiftKey && e.keyCode == "I".charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.shiftKey && e.keyCode == "J".charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.keyCode == "U".charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.keyCode == "S".charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.keyCode == "H".charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.keyCode == "A".charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.keyCode == "F".charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.keyCode == "E".charCodeAt(0)) {
        return false;
      }
    };
  }, []);

  const showPage = (page) => {
    setBookPage(page);
    setShowBookPage(true);
  };

  return (
    <div>
      <Navbar
        wallet={wallet}
        whitelist={whitelist}
        claim={() => setShowClaim(true)}
        openBook={() => setShowBook(true)}
        setShowBook={setShowBook}
        showBook={showBook}
      />
      {/* {handleBookOwnership()} */}
      {showBook ? (
        <BookPage showPage={showPage} />
      ) : (
        <Fragment>
          <Hero
            connected={wallet.walletConnected}
            // openBook={() => setShowBook(true)}
          />
          <Video id="video" />
          <About id="about" />
          <Inside id="inside" />
        </Fragment>
      )}
      <Footer />
      <ClaimModal
        wallet={wallet}
        whitelist={whitelist}
        toast={toast}
        mint={mintBook}
        show={showClaim}
        close={() => setShowClaim(false)}
      />
      <BookModal
        show={showBookPage}
        have_book={have_book}
        page={book_page}
        close={() => setShowBookPage(false)}
      />
      <ToastContainer theme="dark" />
    </div>
  );
};

export default Book;
