import s from "./about.module.scss";
import Sign_3 from "../../../../assets/book/sign_3.png";
import Book_3d from "../../../../assets/book/book_3d.png";
import Book_360 from "../../../../assets/book/book_360.mp4";
import Book_gif from "../../../../assets/book/Jordi-Molla-Book-360.gif";

const About = (props) => {
  return (
    <div {...props} className={s.container}>
      <div className={s.grid}>
        <div className={s.desc}>
          <h1>ABOUT BOOK</h1>
          <img src={Sign_3} alt="sign" />
          <p>
            {`In the book you will find images of the creation of some of the
            greatest works in his 30+ years as a professional painter. These
            were produced during a period of over a year where he isolated and
            totally immersed himself in his creative process, highly inspired &
            near-fanatically driven, fuelled by espresso and nicotine, he went
            to the place great artists spend their lives reaching for, where the
            ideas and paint command the spirit to push past all the boundaries.`}
          </p>
        </div>
        <div className={s.book}>
          {/* <img src={Book_3d} alt="Book_3d" /> */}
          <div className={s.cover}>
            <img src={Book_gif} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
