import s from "./navbar.module.scss";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Menu from "../../../../assets/book/menu.svg";
import open_book from "../../../../assets/book/open_book.png";
import close_btn from "../../../../assets/book/close.svg";
import { useState } from "react";
import cn from "classnames";
import {
  onWhitelist,
  isMinted,
  ownsBook,
} from "../../../../utilities/web3Functions";

const Links = (props) => {
  let [showClaimed, setShowClaimed] = useState(false);
  let [haveBook, setHaveBook] = useState(false); // need to check
  let [connected, setConnected] = useState(false);
  let [isWhitelisted, setWhitelisted] = useState(false);
  let [hasClaimed, setHasClaimed] = useState(false);

  const onConnect = () => {
    props.wallet.connectWallet();
  };
  const handleWhitelisted = () => {
    const res = onWhitelist(props.whitelist, props.wallet.accountAddress);
    if (res && !isWhitelisted) {
      setWhitelisted(true);
      setShowClaimed(true);
    }
  };
  const handleIsMinted = () => {
    if (props.wallet.walletConnected) {
      isMinted(
        props.whitelist,
        props.wallet.accountAddress,
        props.wallet.web3
      ).then((mint) => {
        if (mint) {
          if (!hasClaimed) {
            setHasClaimed(true);
          }
        }
      });
    }
  };

  const handleIsOwner = () => {
    if (props.wallet.walletConnected) {
      ownsBook(props.wallet.web3, props.wallet.accountAddress).then(
        (isOwner) => {
          if (isOwner && !haveBook) {
            setHaveBook(true);
          }
        }
      );
    }
  };
  const handleStatus = () => {
    handleIsOwner();
    handleWhitelisted();
    handleIsMinted();
    if (hasClaimed) {
      if (haveBook) {
        return !props.showBook ? (
          <img
            className={s.open_book_tooltip}
            src={open_book}
            alt='open_book'
          />
        ) : null;
      } else {
        return <div className={s.tooltip}>you don’t have book</div>;
      }
      // return <div className={s.tooltip}>You have already minted this NFT</div>;
    } else if (!isWhitelisted && connected && !haveBook) {
      return (
        <div className={s.tooltip}>
          You are not eligible to claim the book NFT
        </div>
      );
    }
    return null;
  };

  const handleDisconnect = () => {
    props.wallet.disconnectWallet();
    setConnected(false);
  };
  const handleCanDisconnect = () => {
    if (connected) {
      return (
        <button className={s.disconnect} onClick={() => handleDisconnect()}>
          Disconnect
        </button>
      );
    } else return null;
  };
  const buyBook = () => {
    return (
      <a
        href={
          haveBook ? null : "https://opensea.io/collection/digital-art-book"
        }
      ></a>
    );
  };

  const handleBook = () => {
    return props.wallet.walletConnected ? (
      <a
        href={
          haveBook ? null : "https://opensea.io/collection/digital-art-book"
        }
      >
        <button
          className={cn(s.claim)}
          onClick={() => (haveBook ? props.openBook() : null)}
        >
          <span>{haveBook ? "OPEN THE BOOK" : "BUY THE BOOK"}</span>
        </button>
      </a>
    ) : null;
  };

  const handleClaimButton = () => {
    if (showClaimed) {
      console.log("hasClaimed:", hasClaimed);
      return hasClaimed ? null : (
        <button className={cn(s.claim)} onClick={() => props.claim()}>
          <span>CLAIM</span>
        </button>
      );
    }
    return null;
  };
  if (!connected && props.wallet.walletConnected) {
    setConnected(true);
  }
  return (
    <div className={s.menu_wrapper}>
      <ul className={s.links}>
        <li>
          <a href='/'>Home</a>
        </li>
        <li onClick={() => props.setShowBook(false)}>
          <AnchorLink href={props.showBook ? "" : "#video"} offset={140}>
            Video
          </AnchorLink>
        </li>
        <li onClick={() => props.setShowBook(false)}>
          <AnchorLink href={props.showBook ? "" : "#about"} offset={140}>
            About
          </AnchorLink>
        </li>
        <li onClick={() => props.setShowBook(false)}>
          <AnchorLink href={props.showBook ? "" : "#inside"} offset={180}>
            Book Preview
          </AnchorLink>
        </li>
      </ul>
      <div
        className={s.buttons}
        onClick={() => {
          if (props.setShowDrawer) {
            props.setShowDrawer(false);
          }
        }}
      >
        {handleCanDisconnect()}
        <button
          className={cn(
            s.connect,
            props.wallet.accountAddress ? s.active : null
          )}
          onClick={() => onConnect()}
        >
          {props.wallet.accountAddress
            ? formatAddress(props.wallet.accountAddress)
            : "Connect Wallet"}
        </button>
        {handleBook()}
        {handleClaimButton()}
        {handleStatus()}
      </div>
    </div>
  );
};

const Navbar = (props) => {
  let [showDrawer, setShowDrawer] = useState(false);
  return (
    <div className={s.container}>
      {showDrawer ? (
        <div className={s.drawer}>
          <div
            className={s.menu}
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            <div className={s.row}>
              <h1>MENU</h1>
              <img
                src={close_btn}
                alt='close_btn'
                onClick={() => {
                  setShowDrawer(false);
                }}
              />
            </div>
            <Links {...props} setShowDrawer={setShowDrawer} />
          </div>
        </div>
      ) : null}
      <div className={s.navbar}>
        <div className={s.logo}></div>
        <Links {...props} />
        <img
          className={s.menu}
          src={Menu}
          alt='Menu'
          onClick={() => {
            setShowDrawer(!showDrawer);
          }}
        />
      </div>
    </div>
  );
};

export default Navbar;

const formatAddress = (address) => {
  return (
    address.slice(0, 6) +
    "..." +
    address.slice(address.length - 4, address.length)
  );
};
