import styles from './AuctionUI.module.scss';
import { AUCTION_END_TIME, AUCTION_START_TIME, OPENSEA_ITEM_URL, useCountdown } from './api';
import imageOpensea from "../../assets/opensea.svg";

const format = (n, term) => {
  if (n === 0) return '';
  if (n === 1) return `${n} ${term} `;
  return `${n} ${term}s `;
}

const Countdown = () => {
  const { days, hours, minutes, seconds, isFinished } = useCountdown(AUCTION_START_TIME)
  const auctionEndCoundown = useCountdown(AUCTION_END_TIME)
  
  return <div className={styles.Countdown}>
    <div style={{ width: '100%', textAlign: 'center', color: '#A40D30'}}>
      {!isFinished ?
        <div>Auction starts in</div> 
        : auctionEndCoundown.isFinished ?
          <div>The auction is over</div> 
          :
          <div>The auction has begun</div> 
      }
      <a href={OPENSEA_ITEM_URL} target='_blank' rel='noreferrer' className={styles.BidButton + ' ' + styles.OpenseaButton}>
        <img src={imageOpensea} alt='OpenSea logo' style={{ width: '30px', height: '30px', marginRight: '10%'}}/>
        View on OpenSea
      </a>
      <div>{format(days, 'day')}{format(hours, 'hour')}{format(minutes, 'minute')}{format(seconds, 'second')}</div>
    </div>
  </div>
}

export default Countdown;