const PauseVideo = () => {
  return (
    <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_b_4_20)">
        <rect width="45.2104" height="45.2104" rx="12" fill="#C68C8C" fillOpacity="0.32" />
        <rect x="0.75" y="0.75" width="43.7104" height="43.7104" rx="11.25" stroke="url(#paint0_linear_4_20)" strokeWidth="1.5" />
      </g>
      <rect x="16" y="13" width="5" height="19" rx="2.5" fill="url(#paint1_linear_4_20)" />
      <rect x="25" y="13" width="5" height="19" rx="2.5" fill="url(#paint2_linear_4_20)" />
      <defs>
        <filter id="filter0_b_4_20" x="-24" y="-24" width="93.2104" height="93.2104" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImage" stdDeviation="12" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_4_20" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_4_20" result="shape" />
        </filter>
        <linearGradient id="paint0_linear_4_20" x1="0" y1="0" x2="39.2511" y2="49.8981" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0.2" />
          <stop offset="0.494792" stopColor="#8A92B3" stopOpacity="0" />
          <stop offset="0.9999" stopColor="#899AE4" stopOpacity="0.3" />
          <stop offset="1" stopColor="#D3D6E3" stopOpacity="0.2" />
        </linearGradient>
        <linearGradient id="paint1_linear_4_20" x1="18.5" y1="13" x2="18.5" y2="32" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="0.433222" stopColor="#E0E0E0" />
          <stop offset="1" stopColor="#EEEEEE" />
        </linearGradient>
        <linearGradient id="paint2_linear_4_20" x1="27.5" y1="13" x2="27.5" y2="32" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="0.433222" stopColor="#E0E0E0" />
          <stop offset="1" stopColor="#EEEEEE" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default PauseVideo;
