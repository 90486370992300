import Web3 from "web3";
import { useEffect, useState } from "react";
import { OpenSeaPort, Network, EventType } from "opensea-js";
import { OrderSide, WyvernSchemaName } from "opensea-js/lib/types";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import BigNumber from "bignumber.js";
import { toast } from "react-toastify";
import { Multicall } from "ethereum-multicall";

import nftFunctionsABI from "../../abis/nft_functions.json";
import poolABI from "../../abis/staking_pool.json";
import pool2ABI from "../../abis/lp_v3_staking_pool.json";
import kwlFunctionsABI from "../../abis/kwl_functions.json";

export const AUCTION_ITEM = {
  tokenAddress: "0x53b217E1f76925b3AFD4A0d3cE0dD8e11A822846",
  tokenId: "1",
  schemaName: WyvernSchemaName.ERC721,
};

const INFURIA_URL =
  "https://mainnet.infura.io/v3/1d1bfc67714b4f3caa1cae4c73475a25";

const INFURA_ID = "1d1bfc67714b4f3caa1cae4c73475a25";

export const WETH_ADDRESS = "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2";

export const NETWORK = Network.Main;

export const AUCTION_START_TIME = 1638187200;
export const AUCTION_END_TIME = 1638360000;

export const ETHERSCAN_ADDRESS = "https://etherscan.io/";

export const OPENSEA_ITEM_URL =
  "https://opensea.io/assets/0x53b217E1f76925b3AFD4A0d3cE0dD8e11A822846/1";

export const OPENSEA_API_KEY = "09ac1fff99f943a79d6f75710b5a7b41";

export const IS_API_OUTAGE = false;

export const SHOW_AUCTION_UI = false;

const NFT_FUNCTIONS_ADDRESS = "0x416DE360946eCabb4665781Cae3417a1D0C90690";
const RAINBOW_POOL_ADDRESS = "0xA240a29355EB847667178F860b13124DEf0e71C7";
const UNICORN_POOL_1_ADDRESS = "0x2927a1712f3Eb8ED22bBFeccaBB4392fC16A4f6A";
const UNICORN_POOL_2_ADDRESS = "0x816EEb62449cda89253B5a18aa364EBfAb4fd9d3";
const KWL_ADDRESS = "0x59193Aa46b64691E91398222db822cD810cE694C";

const POOL_IDS = [1, 2, 3];

export const PointType = {
  NONE: 0,
  RAINBOW: 1,
  UNICORN: 2,
  ETH: 3,
};

export const PoolInfo = {
  1: {
    name: "Public Pool",
    description: (
      <ul>
        <li>1 x Jordi Molla MASK NFT</li>
      </ul>
    ),
    defaultPointType: PointType.ETH,
  },
  2: {
    name: "Raini Pool 🌈 🦄",
    description: (
      <ul>
        <li>1 x Jordi Molla MASK NFT</li>
        <li>Mint with Rainbows or Unicorns</li>
      </ul>
    ),
    defaultPointType: PointType.NONE,
  },
  3: {
    name: "Raini Pool 🦄",
    description: (
      <ul>
        <li>1 x Jordi Molla MASK NFT</li>
        <li>Mint with Unicorns</li>
      </ul>
    ),
    defaultPointType: PointType.UNICORN,
  },
};

export const getContract = (provider, address, abi) => {
  return new provider.eth.Contract(abi, address);
};

const multiMethodMulticall = async ({ calls, provider, address }) => {
  const config = { web3Instance: provider };
  if (address) {
    config.multicallCustomContractAddress = address;
  }

  const multicall = new Multicall(config);
  const contractCalls = Object.entries(calls).map(([key, value], i) => {
    const [contract, method, params] = value;
    return {
      reference: key,
      contractAddress: contract.options.address,
      abi: contract.options.jsonInterface,
      calls: [
        {
          reference: i,
          methodName: method,
          methodParameters: params,
        },
      ],
    };
  });

  const data = await multicall.call(contractCalls);
  const results = Object.entries(data.results)
    .map(([key, value]) => {
      const result = value.callsReturnContext[0].returnValues;
      const fn = calls[key][3];
      return [key, result, fn];
    })
    .reduce((obj, [key, value, fn]) => {
      const func = fn || ((x) => BigNumber(x[0].hex).toFixed());
      return {
        ...obj,
        [key]: func(value),
      };
    }, {});
  return results;
};

const getPointBalance = async (provider, address) => {
  const rainbowPool = getContract(provider, RAINBOW_POOL_ADDRESS, poolABI);
  const unicornPool1 = getContract(provider, UNICORN_POOL_1_ADDRESS, poolABI);
  const unicornPool2 = getContract(provider, UNICORN_POOL_2_ADDRESS, pool2ABI);

  const calls = {
    rainbows: [rainbowPool, "balanceOf", [address]],
    unicorns1: [unicornPool1, "balanceOf", [address]],
    unicorns2: [unicornPool2, "balanceOf", [address]],
  };

  const result = await multiMethodMulticall({
    provider,
    calls,
  });

  const unicorns = BigNumber(result.unicorns1).plus(result.unicorns2).toFixed();

  return { rainbows: result.rainbows, unicorns };
};

export const usePointBalance = (provider, address) => {
  const [balances, setBalances] = useState({ rainbows: 0, unicorns: 0 });
  const isConnected = provider && address;

  const getData = async (provider, address) => {
    try {
      setBalances(await getPointBalance(provider, address));
    } catch (error) {}
  };

  useEffect(() => {
    if (!isConnected) return;
    getData(provider, address);
    //const interval = setInterval(getData, 15000)
    return () => {
      //clearInterval(interval);
    };
  }, [provider, address]);

  return { balances, getBalances: () => getData(provider, address) };
};

export async function mint({
  provider,
  cart,
  cartPointType,
  totalEth,
  sig,
  maxMints,
  address,
  setModal,
}) {
  let returnError = false;
  const items = cart
    .map((amount, index) => ({
      id: index,
      amount,
      pointType: cartPointType[index],
    }))
    .filter((x) => x.amount);

  const krewWL = getContract(provider, KWL_ADDRESS, nftFunctionsABI);

  // console.log([
  //   items.map(x => x.id),                             //poolType
  //   items.map(x => x.amount),                         //amount
  //   items.map(x => x.pointType === PointType.UNICORN), //useUnicorns
  //   [RAINBOW_POOL_ADDRESS],                           //rainbowPools
  //   [UNICORN_POOL_1_ADDRESS, UNICORN_POOL_2_ADDRESS], //unicornPools
  //   sig || '',                                        //sig
  //   maxMints || 0,
  // ])

  try {
    let toastId;
    let hash;

    await krewWL.methods
      .mint(
        [1], //poolType
        [1], //amount
        [false], //useUnicorns
        [RAINBOW_POOL_ADDRESS], //rainbowPools
        [UNICORN_POOL_1_ADDRESS, UNICORN_POOL_2_ADDRESS], //unicornPools
        sig || 0, //sig
        maxMints || 0 //maxMints
      )
      .send({
        from: address,
      })
      .once("transactionHash", (_hash) => {
        hash = _hash;
        setModal("");
        toastId = toast(
          <TxnToast hash={hash} message={"Transaction in progress"} />,
          {
            autoClose: false,
            closeOnClick: false,
          }
        );
      })
      .on("error", (error) => {
        toast.dismiss(toastId);
        returnError = true;
        if (error.code === 4001) {
          return;
        }
        if (hash) {
          toast.error(<TxnToast hash={hash} message={"Transaction failed"} />, {
            autoClose: 10000,
            closeOnClick: false,
          });
        } else {
          toast.error("There was an error sending this transaction", {
            autoClose: 10000,
            closeOnClick: false,
          });
        }
      })
      .then(async () => {
        toast.dismiss(toastId);
        toast(<TxnToast hash={hash} message={"Transaction successful!"} />, {
          autoClose: 5000,
          closeOnClick: false,
        });
      });
    return returnError;
  } catch (error) {
    console.log(error);
    return true; // returnError = true
  }
}

const getPools = async (provider, address) => {
  const nftFunction = getContract(
    provider,
    NFT_FUNCTIONS_ADDRESS,
    nftFunctionsABI
  );
  const poolTypesCalls = POOL_IDS.reduce(
    (obj, id) => ({
      ...obj,
      [id + "-type"]: [
        nftFunction,
        "poolTypes",
        [id],
        (x) => ({
          costInUnicorns: BigNumber(x[0].hex).toNumber(),
          costInRainbows: BigNumber(x[1].hex).toNumber(),
          costInEth: BigNumber(x[2].hex).toNumber(),
          maxMintsPerAddress: x[3],
          supply: x[4],
          mintTimeStart: x[5],
          requiresWhitelist: !!x[6],
        }),
      ],
    }),
    []
  );

  let userMintedCalls = !address
    ? []
    : POOL_IDS.reduce(
        (obj, id) => ({
          ...obj,
          [id + "-userMinted"]: [
            nftFunction,
            "numberMintedByAddress",
            [address, id],
            (x) => BigNumber(x[0].hex).toNumber(),
          ],
        }),
        []
      );

  const amountMintedCalls = POOL_IDS.reduce(
    (obj, id) => ({
      ...obj,
      [id + "-totalMinted"]: [
        nftFunction,
        "numberOfPoolMinted",
        [id],
        (x) => BigNumber(x[0].hex).toNumber(),
      ],
    }),
    []
  );

  const result = await multiMethodMulticall({
    provider,
    calls: {
      ...poolTypesCalls,
      ...amountMintedCalls,
      ...userMintedCalls,
      maxMintsPerTx: [
        nftFunction,
        "maxMintsPerTx",
        [],
        (x) => BigNumber(x[0].hex).toNumber(),
      ],
      minPointsPercentToMint: [
        nftFunction,
        "minPointsPercentToMint",
        [],
        (x) => BigNumber(x[0].hex).toNumber(),
      ],
      mintingFeeBasisPoints: [
        nftFunction,
        "mintingFeeBasisPoints",
        [],
        (x) => BigNumber(x[0].hex).toNumber(),
      ],
      unicornToEth: [
        nftFunction,
        "unicornToEth",
        [],
        (x) => BigNumber(x[0].hex).toNumber(),
      ],
      rainbowToEth: [
        nftFunction,
        "rainbowToEth",
        [],
        (x) => BigNumber(x[0].hex).toNumber(),
      ],
    },
  });

  const pools = POOL_IDS.map((id) => ({
    ...result[id + "-type"],
    ...PoolInfo[id],
    id,
    totalMinted: result[id + "-totalMinted"],
    userMinted: result[id + "-userMinted"] ?? 0,
  }));

  const kwlContract = getContract(provider, KWL_ADDRESS, kwlFunctionsABI);
  const kwlMinted = address
    ? parseInt(await kwlContract.methods.numberMintedByAddress(address).call())
    : 0;

  pools.push({
    name: "Krew Whitelist",
    isKwl: true,
    requiresWhitelist: true,
    hasMinted: !!kwlMinted,
    description: (
      <ul>
        <li>1 x Jordi Molla MASK NFT</li>
      </ul>
    ),
  });

  return {
    pools,
    maxMintsPerTx: result.maxMintsPerTx,
    minPointsPercentToMint: result.minPointsPercentToMint,
    mintingFeeBasisPoints: result.mintingFeeBasisPoints,
    unicornToEth: result.unicornToEth,
    rainbowToEth: result.rainbowToEth,
  };
};

export const usePools = (provider, address) => {
  const [pools, setPools] = useState({ pools: [] });
  const isConnected = provider;

  const getData = async (provider, address) => {
    console.log("getting pool data");
    try {
      setPools(await getPools(provider, address));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!isConnected) return;
    getData(provider, address);
    const interval = setInterval(() => getData(provider, address), 15000);
    return () => {
      clearInterval(interval);
    };
  }, [provider, address]);

  return { ...pools, getPools: () => getData(provider, address) };
};

const getOrders = async (item, seaport) => {
  const { orders } = await seaport.api.getOrders({
    asset_contract_address: item.tokenAddress,
    token_id: item.tokenId,
    side: OrderSide.Buy,
  });
  return orders;
};

export const useBids = (item, seaport) => {
  const [bids, setBids] = useState([]);
  const isConnected = !!seaport;
  useEffect(() => {
    if (!isConnected) return;
    const getData = async () => {
      try {
        setBids(await getOrders(item, seaport));
      } catch (error) {}
    };
    getData();
    const interval = setInterval(getData, 15000);
    return () => {
      clearInterval(interval);
    };
  }, [seaport]);

  const addBid = (bid) => {
    setBids([...bids, bid]);
  };

  return { bids, addBid };
};

export const useWeth = (accountAddress, seaport) => {
  const [wethBalance, setWethBalance] = useState();
  const [wethAllowance, setWethAllowance] = useState();

  useEffect(() => {
    if (!seaport || !accountAddress) return;
    const getData = async () => {
      try {
        const balance = await seaport.getTokenBalance({
          accountAddress,
          tokenAddress: WETH_ADDRESS,
          schemaName: WyvernSchemaName.ERC20,
        });
        setWethBalance(balance.toFixed());
        const allowance = await seaport._getApprovedTokenCount({
          accountAddress,
          tokenAddress: WETH_ADDRESS,
        });
        setWethAllowance(allowance.toFixed());
      } catch (error) {}
    };
    getData();
    const interval = setInterval(getData, 10000);
    return () => {
      clearInterval(interval);
    };
  }, [seaport, accountAddress]);

  return { wethBalance, wethAllowance };
};

export const useCountdown = (endTime, delay = 0) => {
  const [time, setTime] = useState([]);
  useEffect(() => {
    const getTime = async () => {
      setTime(Math.floor(Date.now() / 1000));
    };
    getTime();
    const interval = setInterval(getTime, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  const totalSeconds = Math.max(endTime - time + delay, 0);
  const days = Math.floor(totalSeconds / (24 * 3600));
  const hours = Math.floor(totalSeconds / 3600) % 24;
  const minutes = Math.floor(totalSeconds / 60) % 60;
  const seconds = totalSeconds % 60;
  const isFinished = !totalSeconds;

  return { days, hours, minutes, seconds, isFinished };
};

export const useEthToUsd = () => {
  const [ethValue, setEthValue] = useState(0);
  useEffect(() => {
    const getData = async () => {
      let response = await fetch(
        "https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD"
      );
      response = await response.json();
      console.log(response?.USD);
      setEthValue(parseFloat(response.USD));
    };
    getData();
  }, []);

  const ethToUsd = (eth) => ethValue * eth;

  return ethToUsd;
};

export const useWallet = () => {
  const [web3, setWeb3] = useState();
  const [seaport, setSeaport] = useState();
  const [accountAddress, setAccountAddress] = useState();
  const [checkAddressInterval, setCheckAddressInterval] = useState();
  const [walletConnected, setWalletConnected] = useState(false);
  const [ethBalance, setEthBalance] = useState("0");

  useEffect(() => {
    const provider = new Web3.providers.HttpProvider(INFURIA_URL);
    const seaport = new OpenSeaPort(provider, {
      networkName: NETWORK,
      apiKey: OPENSEA_API_KEY,
    });
    setSeaport(seaport);
    setWeb3(new Web3(provider));

    return () => {
      if (checkAddressInterval) {
        clearInterval(checkAddressInterval);
      }
    };
  }, []);

  const getBalance = async () => {
    const balance = await web3.eth.getBalance(accountAddress);
    setEthBalance(BigNumber(balance).toFixed(0));
  };

  useEffect(() => {
    let interval;

    if (accountAddress) {
      getBalance();
      interval = setInterval(getBalance, 15000);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [accountAddress]);

  const connectWallet = async (getPools = () => {}) => {
    let web3;
    let provider;
    try {
      const providerOptions = {
        walletconnect: {
          package: WalletConnectProvider,
          options: {
            infuraId: INFURA_ID,
          },
        },
        walletlink: {
          package: CoinbaseWalletSDK,
          options: {
            infuraId: INFURA_ID,
          },
        },
      };

      const web3Modal = new Web3Modal({
        network: process.env.REACT_APP_NETWORK_NAME,
        //cacheProvider: true, // optional
        theme: "dark",
        providerOptions, // required
      });

      provider = await web3Modal.connect();
      web3 = new Web3(provider);

      if (!web3) return;

      setWeb3(web3);

      const seaport = new OpenSeaPort(provider, {
        networkName: NETWORK,
        apiKey: OPENSEA_API_KEY,
      });
      setSeaport(seaport);

      const accountAddress =
        web3.currentProvider.selectedAddress ||
        web3.currentProvider.accounts[0];
      setAccountAddress(accountAddress);

      setCheckAddressInterval(
        setInterval(() => {
          const newAddress =
            web3.currentProvider.selectedAddress ||
            web3.currentProvider.accounts[0];
          setWalletConnected((connected) => {
            if (!connected) return connected;
            setAccountAddress((address) =>
              address !== newAddress ? newAddress : address
            );
            return connected;
          });
        }, 1000)
      );
      setWalletConnected(true);
      getPools();
    } catch (error) {
      toast.error("An error occured connecting your wallet");
    }
  };

  const disconnectWallet = () => {
    setAccountAddress();
    setWalletConnected(false);
  };

  return {
    connectWallet,
    web3,
    seaport,
    accountAddress,
    disconnectWallet,
    ethBalance,
    walletConnected,
    getEthBalance: getBalance,
  };
};

export const useCancelBid = (accountAddress, seaport) => {
  const [isCancelling, setIsCancelling] = useState();
  const [cancelledList, setCancelledList] = useState([]);

  const cancelBid = async (order) => {
    setIsCancelling(order.hash);
    const result = await cancel(order, accountAddress, seaport);
    if (!result.error) {
      setCancelledList([...cancelledList, order.hash]);
    }
    setIsCancelling(undefined);
  };

  return { isCancelling, cancelledList, cancelBid };
};

const cancel = async (order, accountAddress, seaport) => {
  try {
    const result = await seaport.cancelOrder({ order, accountAddress });
    return { result };
  } catch (error) {
    toast.error("An error occured canceling this bid");
    return { error };
  }
};

export const extractBidData = (bid) => {
  const { currentPrice, createdTime, maker, hash } = bid;
  const price = currentPrice.div("1000000000000000000").toFixed();
  const created = new Date(createdTime * 1000);
  const shortAddress =
    maker.substr(2, 4) + "..." + maker.substr(maker.length - 4);
  return { price, created, shortAddress, address: maker, hash };
};

export const sortBids = (a, b) => {
  const diff = b.currentPrice.minus(a.currentPrice);
  if (diff.gt(0)) return 1;
  if (diff.lt(0)) return -1;
  return 0;
};

export const createBid = async (
  bidAmount,
  accountAddress,
  seaport,
  setModal,
  setBid,
  addBid,
  setIsBidding
) => {
  try {
    setIsBidding(true);
    const offer = await seaport.createBuyOrder({
      asset: AUCTION_ITEM,
      accountAddress,
      startAmount: bidAmount,
    });
    setModal();
    setBid(0);
    addBid(offer);
    setIsBidding(false);
  } catch (error) {
    setIsBidding(false);
    console.log(error);
    toast.error("An error occured placing this bid");
  }
};

export const useWrapEth = (amount, accountAddress, seaport, setModal) => {
  const [isWrapping, setIsWrapping] = useState();

  const wrapEth = async (order) => {
    setIsWrapping(true);
    await wrap(amount, accountAddress, seaport);
    setIsWrapping(false);
    setModal();
  };

  return { isWrapping, wrapEth };
};

const wrap = async (amountInEth, accountAddress, seaport) => {
  try {
    const result = await seaport.wrapEth({ amountInEth, accountAddress });
    return { result };
  } catch (error) {
    return { error };
  }
};

export const TxnToast = ({ hash, message }) => {
  return (
    <>
      <p>{message}</p>
      <a
        style={{ textDecoration: "underline" }}
        href={ETHERSCAN_ADDRESS + "tx/" + hash}
        target='_blank'
        rel='noreferrer'
      >
        Click here for details
      </a>
    </>
  );
};

export const fromWei = (n) => BigNumber(n).div("1000000000000000000");
export const toWei = (n) => BigNumber(n).times("1000000000000000000");
