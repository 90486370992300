const Instagram = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.38582 0C2.86899 0 0 2.86899 0 6.38582V15.6142C0 19.131 2.86899 22 6.38582 22H15.6142C19.131 22 22 19.131 22 15.6142V6.38582C22 2.86899 19.131 0 15.6142 0H6.38582ZM6.38582 1.69231H15.6142C18.2154 1.69231 20.3077 3.78125 20.3077 6.38582V15.6142C20.3077 18.2154 18.2188 20.3077 15.6142 20.3077H6.38582C3.78456 20.3077 1.69231 18.2188 1.69231 15.6142V6.38582C1.69231 3.78456 3.78125 1.69231 6.38582 1.69231ZM17.3462 3.38462C16.6454 3.38462 16.0769 3.95312 16.0769 4.65385C16.0769 5.35457 16.6454 5.92308 17.3462 5.92308C18.0469 5.92308 18.6154 5.35457 18.6154 4.65385C18.6154 3.95312 18.0469 3.38462 17.3462 3.38462ZM11 5.07692C7.73768 5.07692 5.07692 7.73768 5.07692 11C5.07692 14.2623 7.73768 16.9231 11 16.9231C14.2623 16.9231 16.9231 14.2623 16.9231 11C16.9231 7.73768 14.2623 5.07692 11 5.07692ZM11 6.76923C13.3468 6.76923 15.2308 8.65325 15.2308 11C15.2308 13.3468 13.3468 15.2308 11 15.2308C8.65325 15.2308 6.76923 13.3468 6.76923 11C6.76923 8.65325 8.65325 6.76923 11 6.76923Z"
        fill="#707482"
      />
    </svg>
  );
};

export default Instagram;
