import { Fragment, useEffect, useState } from "react";
import s from "./book_modal.module.scss";
import { Document, Page, pdfjs } from "react-pdf";
import book from "../../../../assets/book/240d7fde2a215e95bcda481022fb3033.pdf";
import close_btn from "../../../../assets/book/close.svg";
import next_page from "../../../../assets/book/next_page.svg";
import prev_page from "../../../../assets/book/prev_page.svg";
import cn from "classnames";

const Modal = (props) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const [numPages, setNumPages] = useState(null);
  const [page, setPage] = useState(1);
  const [width, setWidth] = useState(900);
  const [height, setHeight] = useState(750);
  //EHSAN

  document.addEventListener("contextmenu", (event) => {
    event.preventDefault();
  });

  useEffect(() => {
    setPage(props.page);
  }, [props.page]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  useEffect(() => {
    window.addEventListener(
      "resize",
      function (e) {
        setSize(e.currentTarget);
      },
      true
    );
    setSize(window);
  }, []);

  const setSize = function (window) {
    let w = window.innerWidth;
    let h = window.innerHeight;

    if (w < 660) {
      setHeight(350);
    } else if (w < 800) {
      setHeight(650);
    } else {
      setHeight(750);
    }
  };

  const goNext = () => {
    setPage(page + 1 >= 172 ? 1 : page + 1);
  };

  const goPrev = () => {
    setPage(page - 1 <= 0 ? 172 : page - 1);
  };

  return (
    <Fragment>
      {props.show ? (
        <div
          className={s.container}
          id="book_modal"
          onClick={(e) => {
            if (e.target.getAttribute("id") == "book_modal") props.close();
          }}
        >
          <div className={s.card}>
            <img
              className={s.close_btn}
              src={close_btn}
              alt="close_btn"
              onClick={(e) => {
                props.close();
              }}
            />
            <div className={s.book_holder}>
              <Document
                file={book}
                onLoadSuccess={onDocumentLoadSuccess}
                className={cn(s.book_container)}
              >
                <Page
                  height={height}
                  className={s.book}
                  pageNumber={parseInt(page)}
                />
              </Document>
            </div>
          </div>

          <div className={s.controllers}>
            <img src={prev_page} className={s.prev_page} onClick={goPrev} />
            <div
              className={s.close_btn_bottom}
              onClick={(e) => {
                props.close();
              }}
            >
              <span>CLOSE</span>
            </div>
            <img src={next_page} className={s.next_page} onClick={goNext} />
          </div>
        </div>
      ) : null}
    </Fragment>
  );
};

export default Modal;
