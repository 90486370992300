const Twitter = () => {
  return (
    <svg
      width="24"
      height="20"
      viewBox="0 0 24 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.2666 3.01208C22.4372 3.38041 21.5463 3.62828 20.6112 3.74008C21.5662 3.16808 22.2986 2.26241 22.6435 1.18254C21.7508 1.71208 20.7611 2.09688 19.7072 2.30488C18.864 1.40614 17.6628 0.844543 16.3333 0.844543C13.7801 0.844543 11.7105 2.91501 11.7105 5.46734C11.7105 5.82961 11.7521 6.18321 11.8301 6.52034C7.98816 6.32794 4.58216 4.48714 2.30109 1.68954C1.90416 2.37248 1.67622 3.16634 1.67622 4.01481C1.67622 5.61814 2.49176 7.03341 3.73196 7.86194C2.97449 7.83768 2.26122 7.62968 1.63809 7.28388C1.63809 7.30381 1.63809 7.32201 1.63809 7.34194C1.63809 9.58228 3.23102 11.4508 5.34656 11.8746C4.95916 11.9803 4.55009 12.0367 4.12802 12.0367C3.83076 12.0367 3.54042 12.0072 3.25876 11.9543C3.84722 13.7908 5.55456 15.1281 7.57736 15.1653C5.99569 16.4055 4.00236 17.1448 1.83569 17.1448C1.46302 17.1448 1.09469 17.1231 0.732422 17.0798C2.77862 18.3911 5.20789 19.1563 7.81916 19.1563C16.3229 19.1563 20.9717 12.1121 20.9717 6.00294C20.9717 5.80274 20.9674 5.60341 20.9587 5.40494C21.8626 4.75234 22.647 3.93854 23.2666 3.01208Z"
        fill="#707482"
      />
    </svg>
  );
};

export default Twitter;
