import { useEffect, useRef, useState } from 'react';
import styles from './AccountButton.module.scss';
import jazzicon from '@metamask/jazzicon';
import BigNumber from 'bignumber.js';
import { fromWei } from '../AuctionUI/api';


const AccountButton = ({ wallet}) => {
  const { accountAddress, connectWallet, disconnectWallet, web3, ethBalance } = wallet;
  

  const jazziconRef = useRef();

  useEffect(() => {
    if (accountAddress) {
      console.log('getting jazzicon')
      const icon = jazzicon(16, parseInt(accountAddress?.slice(2, 10), 16))
      const current = jazziconRef.current
      current?.appendChild(icon)
      return () => {
        current?.removeChild(icon)
      }
    }
  }, [accountAddress])

  


  if (!accountAddress) {
    return <button className={styles.ConnectButton} onClick={connectWallet}>Connect wallet</button>
  }

  
  
  const shortAddress = accountAddress.substr(0, 6) + '...' + accountAddress.substr(accountAddress.length - 4);
  return (
    <button className={styles.ButtonContainer} onClick={disconnectWallet}>
      <div className={styles.Button}>
        <div className={styles.ButtonText}>
          {fromWei(ethBalance).toFixed(3)} ETH
        </div>
        <div className={styles.Address}>
          <div className={styles.MonospaceText}>
            {shortAddress}
          </div>
          <div className={styles.JazziconContainer} ref={jazziconRef}></div>
        </div>
      </div>
      <div className={styles.CancelButton}>Disconnect wallet</div>
    </button>
    
  )
}

export default AccountButton;