import MainWrapper from "../../../../components/UI/MainWrapper/MainWrapper";
import s from "./masks.module.scss";
import Switch from "react-switch";
import { useEffect, useState } from "react";
import Modal from "../modal";
import warning_icon from "../../../../assets/warning.svg";
import placeholder from "../../../../assets/104_masks/placeholder.png";
import axios from "axios";
import {
  getTokenIds,
  hasClaimed,
  claim,
} from "../../../../utilities/web3Functions";
import { ToastContainer, toast } from "react-toastify";

const MaskImgView = (props) => {
  let [img_url, setImgUrl] = useState(placeholder);
  let [title, setTitle] = useState("Loading...");
  let [claimed, setClaimed] = useState(false);

  useEffect(() => {
    // checkClaimed();
  }, []);

  //handled in onConnect
  // const checkClaimed = async () => {
  //   if ((await props.wallet.web3.eth.getChainId()) == 1) {
  //     let result = await hasClaimed(props.wallet.web3, props.mask.TokenId);
  //     console.log({ result, id: props.mask.TokenId });
  //     setClaimed(result);
  //   }
  // };

  return (
    // <div className={[s.item, row.disabled ? s.disabled : ""].join(" ")}>
    <div className={[s.item].join(" ")}>
      <div className={s.img_holder} token={props.mask.TokenId}>
        <img src={props.mask.Image} alt={props.mask.Name} />
        <span
          className={props.mask.claimable == "false" ? s.claimed : s.available}
        >
          {props.mask.claimable == "false" ? "Claimed" : "Available"}
        </span>
        {props.forSaleMasks.some((i) => i.TokenId === props.mask.TokenId) ? (
          <span className={s.for_sale}>For Sale</span>
        ) : null}
      </div>

      <p>{props.mask.Name}</p>
      <a href={`https://opensea.io/assets/ethereum/0x53b217e1f76925b3afd4a0d3ce0dd8e11a822846/${props.mask.TokenId}`} target="_blank">
        <button className={s.card_opensea_button}> {props.forSaleMasks.some((i) => i.TokenId === props.mask.TokenId) ? `Buy on OpenSea` : `View on OpenSea`}</button>
      </a>
    </div>
  );
};

const AllMasks = (props) => {
  const per_page = 24;
  const [page, setPage] = useState(1);
  return (
    <div>
      <div className={s.list}>
        {(props.show_available
          ? props.show_for_sale
            ? props.masks.filter(
                (k) =>
                  k.claimable == "true" &&
                  props.forSaleMasks.some((item) => item.TokenId === k.TokenId)
              )
            : props.masks.filter((k) => k.claimable == "true")
          : props.show_for_sale
          ? props.masks.filter((k) =>
              props.forSaleMasks.some((item) => item.TokenId === k.TokenId)
            )
          : props.masks
        )
          .slice((page - 1) * per_page, page * per_page)
          .map((row, i) => {
            return (
              <MaskImgView
                wallet={props.wallet}
                key={row.TokenId}
                mask={row}
                forSaleMasks={props.forSaleMasks}
              />
            );
          })}
      </div>
      <div className={s.pagination}>
        {[
          ...Array(
            Math.ceil(
              (props.show_available
                ? props.show_for_sale
                  ? props.masks.filter(
                      (k) =>
                        k.claimable == "true" &&
                        props.forSaleMasks.some(
                          (item) => item.TokenId === k.TokenId
                        )
                    )
                  : props.masks.filter((k) => k.claimable == "true")
                : props.show_for_sale
                ? props.masks.filter((k) =>
                    props.forSaleMasks.some(
                      (item) => item.TokenId === k.TokenId
                    )
                  )
                : props.masks
              ).length / per_page
            )
          ),
        ].map((k, i) => {
          return (
            <span
              key={i}
              className={page == i + 1 ? s.active : null}
              onClick={() => setPage(i + 1)}
            >
              {i + 1}
            </span>
          );
        })}
      </div>
    </div>
  );
};

const YourMasks = (props) => {
  const per_page = 24;
  const [page, setPage] = useState(1);

  let masks_world_data = [
    {
      disabled: false,
      img: require("../../../../assets/104_masks/mask_1.png").default,
      mask: {
        Name: "mask name",
        Image: require("../../../../assets/104_masks/mask_1.png").default,
      },
      title: "Jordi Mask #5411",
      Name: "Name",
      id: 1,
      tokenId: 1,
      button: "CLAIM",
    },
  ];

  return (
    <div className={s.my_masks}>
      {props.masks.length ? (
        <div className={s.list}>
          {props.masks.map((row, i) => {
            return (
              <div
                className={[s.item, row.disabled ? s.disabled : ""].join(" ")}
                key={i}
              >
                {/* <MaskImgView key={row.TokenId} mask={row} /> */}
                <div className={s.img_holder}>
                  <img src={row.Image} alt={row.Name} />
                </div>
                <p>{row.Name}</p>
                <button
                  disabled={row.disabled}
                  onClick={(e) => {
                    props.claim();
                    props.setSelected(row.id);
                    props.setTokenName(row.Name);
                    props.setTokenId(row.TokenId);
                  }}
                  className={s.button}
                >
                  <h1>{row.button}</h1>
                </button>
              </div>
            );
          })}
        </div>
      ) : (
        <div className={s.no_masks}>
          <img src={warning_icon} alt="warning_icon" />
          <p>You are not eligible to claim any mask</p>
        </div>
      )}
    </div>
  );
};

const MasksList = (props) => {
  const [show_modal, setShowModal] = useState(false);
  const [selected, setSelected] = useState(0);
  const [tokenName, setTokenName] = useState("");
  const [tokenId, setTokenId] = useState("");
  const [show_available, setShowAvailable] = useState(false);
  const [show_for_sale, setShowForSale] = useState(false);
  const [show_all_masks, setShowAllMasks] = useState(true);
  const [masks, setMasks] = useState([]);
  const [tokenIds, setTokenIds] = useState([]);
  const [accountMasks, setAccountMasks] = useState([]);
  const [connected, setConnected] = useState(false);
  const [claimed, setClaimed] = useState([]); //array of whether each tokenId in tokenIds hasn claimed
  const [masksSet, setMaskSet] = useState(false);
  const [chainId, setChainId] = useState(0);

  axios.defaults.baseURL = "https://www.krewstudios.com/API";

  const getMaskInfo = async () => {
    try {
      let response = await axios.get("RetriveAssets.php?Rare=true");
      // console.log(response.data);
      setMasks(
        response.data.map((mask) => {
          mask.claimable = mask.IsClaimed;
          delete mask.IsClaimed;
          return mask;
        })
      );
    } catch (error) {
      console.log({ error });
    }
  };

  //only ever called when changing from unclaimed to claimed
  const updateAccountMasks = (id) => {
    const newAm = [];
    accountMasks.forEach((am) => {
      if (id == am.id) {
        am.button = "CLAIMED";
        am.disabled = true;
        newAm.push(am);
      } else {
        newAm.push(am);
      }
    });
  };

  const onConnect = async () => {
    if (props.wallet.walletConnected) {
      let claims;
      if (chainId == 0) {
        setChainId(await props.wallet.web3.eth.getChainId());
      }
      if (!connected) {
        let ids = await getTokenIds(props.wallet.accountAddress, chainId);
        if (!ids) {
          ids = [];
        }
        setTokenIds(ids);
        setConnected(true);

        if (ids.length > 0) {
          const promises = ids.map((id) => {
            return hasClaimed(props.wallet.web3, id);
          });
          claims = await Promise.all(promises);
          setClaimed(claims);
        }
        if (accountMasks.length == 0 && !masksSet) {
          let _masks = masks.map((mask) => {
            return {
              ...mask,
              id: mask.TokenId,
              button: mask.claimable == "false" ? "CLAIMED" : "CLAIM",
              disabled: mask.claimable == "false",
              claimable: mask.claimable == "false",
            };
          });

          // setAccountMasks(_masks);
          setAccountMasks(_masks.filter((k) => ids.includes(k.id)));

          setMaskSet(true);
          // masks.forEach((mask) => {
          //   tokenIds.forEach((id, index) => {
          //     if (chainId > 1) {
          //       //rinkeby
          //       if (
          //         /* convert test tokenIds to mask ids for images*/
          //         (id == 2 && mask.TokenId == 4406) ||
          //         (id == 1 && mask.TokenId == 230) ||
          //         (id == 3 && mask.TokenId == 786) ||
          //         (id == 4 && mask.TokenId == 3294) ||
          //         (id == 9 && mask.TokenId == 6319) ||
          //         (id == 10 && mask.TokenId == 797) ||
          //         (id == 11 && mask.TokenId == 392)
          //       ) {
          //         const newMask = {
          //           id: id,
          //           button: claims[index] ? "CLAIMED" : "CLAIM",
          //           disabled: claims[index] ? true : false,
          //           claimable: claims[index] ? true : false,
          //           ...mask,
          //         };
          //         am.push(newMask);
          //       }
          //     } else {
          //       //mainnet
          //       if (mask.TokenId == id) {
          //         const newMask = {
          //           id: id,
          //           button: claims[index] ? "CLAIMED" : "CLAIM",
          //           disabled: claims[index] ? true : false,
          //           claimable: claims[index] ? true : false,
          //           ...mask,
          //         };
          //         am.push(newMask);
          //       }
          //     }
          //   });
          // });
          // if (am.length > 0) {
          //   setAccountMasks(am);
          //   setMaskSet(true);
          // }
        }
      }
    }
  };

  onConnect();

  useEffect(() => {
    setInterval(() => {
      getMaskInfo();
    }, 1000 * 30);

    getMaskInfo();
  }, []);
  useEffect(() => {
    console.log({ masks });
  }, [masks]);

  return (
    <section id="mask-world-section" className={s.maskWorldSection}>
      <MainWrapper>
        <div className={s.title_row}>
          <h1>CLAIM YOUR MASKS</h1>
          <div className={s.btn_row}>
            {props.wallet.walletConnected ? (
              <button
                onClick={() => {
                  props.wallet.disconnectWallet();
                  setConnected(false);
                  setAccountMasks([]);
                  setClaimed([]);
                  setTokenIds([]);
                }}
                className={s.disconnect}
              >
                Disconnect
              </button>
            ) : null}
            <button
              onClick={() => {
                props.wallet.connectWallet();
              }}
              className={s.connect}
            >
              {props.wallet.walletConnected
                ? `${formatAddress(props.wallet.accountAddress)}`
                : "Connect Wallet"}
            </button>
          </div>
        </div>
        <div className={s.operations}>
          <div className={s.menu_container}>
            <ul className={s.menu}>
              <li
                className={show_all_masks ? s.active : null}
                onClick={() => setShowAllMasks(true)}
              >
                All masks
              </li>
              <li
                className={!show_all_masks ? s.active : null}
                onClick={() => setShowAllMasks(false)}
              >
                Your masks
              </li>
              <li>
                {props.wallet.walletConnected && (
                  <span className={s.available}>Available</span>
                )}
              </li>
            </ul>
          </div>
          {show_all_masks ? (
            <div>
              <ul className={s.total_row}>
                <li>
                  Total:{" "}
                  {
                    (show_available
                      ? show_for_sale
                        ? masks.filter(
                            (k) =>
                              k.claimable == "true" &&
                              props.forSaleMasks.some(
                                (item) => item.TokenId === k.TokenId
                              )
                          )
                        : masks.filter((k) => k.claimable == "true")
                      : show_for_sale
                      ? masks.filter((k) =>
                          props.forSaleMasks.some(
                            (item) => item.TokenId === k.TokenId
                          )
                        )
                      : masks
                    ).length
                  }
                  masks
                </li>
                <li>Available: </li>
                <li>
                  <Switch
                    className={s.checkbox}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    checked={show_available}
                    onChange={(checked) => setShowAvailable(checked)}
                  />
                </li>
                <li style={{ marginLeft: "5px" }}>For Sale: </li>
                <li>
                  <Switch
                    className={s.checkbox}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    checked={show_for_sale}
                    onChange={(checked) => setShowForSale(checked)}
                  />
                </li>
              </ul>
            </div>
          ) : null}
        </div>
        {show_all_masks ? (
          <AllMasks
            masks={masks}
            wallet={props.wallet}
            show_available={show_available}
            forSaleMasks={props.forSaleMasks}
            show_for_sale={show_for_sale}
          />
        ) : (
          <YourMasks
            masks={accountMasks}
            setSelected={setSelected}
            setTokenName={setTokenName}
            setTokenId={setTokenId}
            wallet={props.wallet}
            claim={() => setShowModal(true)}
          />
        )}
      </MainWrapper>

      <Modal
        show={show_modal}
        wallet={props.wallet}
        selected={selected}
        tokenName={tokenName}
        tokenId={tokenId}
        update={updateAccountMasks}
        toast={toast}
        claim={claim}
        hideModal={() => setShowModal(false)}
      />
      <ToastContainer theme="dark" />
    </section>
  );
};

export default MasksList;

const formatAddress = (address) => {
  return (
    address.slice(0, 6) +
    "..." +
    address.slice(address.length - 4, address.length)
  );
};
