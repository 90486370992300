const Burger = () => {
  return (
    <svg
      width="27"
      height="10"
      viewBox="0 0 27 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 9C0 8.44772 0.447715 8 1 8H26C26.5523 8 27 8.44772 27 9C27 9.55228 26.5523 10 26 10H1C0.447715 10 0 9.55228 0 9Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 1C0 0.447715 0.447715 0 1 0H15C15.5523 0 16 0.447715 16 1C16 1.55228 15.5523 2 15 2H1C0.447715 2 0 1.55228 0 1Z"
        fill="white"
      />
    </svg>
  );
};

export default Burger;
