import styled from 'styled-components';
import imgOpenSea from '../../assets/icon-opensea.svg';

export const OpenSeaButton = styled.a`
  display: inline-block;
  width: 150px;
  height: 40px;
  line-height: 40px;
  border-radius: 20px;
  border: solid 1px #812ed9;
  text-align: center;
  color: #812ed9;
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
  background: #170A32;
  z-index: 2;
  padding-left: 0;
  margin-left: 40px;

  &:hover {
    color: #9533f0;
    border: solid 1px #9533f0;
  }
`;

export const OpenSeaIcon = styled.img.attrs({
  src: imgOpenSea,
})`
  position: relative;
  margin-right: 7px;
  height: 18px;
  width: 18px;
  top: 1px;
`;