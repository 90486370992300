import React, { Suspense, useRef } from 'react'
import styles from "./RoadmapCarouselContent.module.scss";

const MaskCarouselImage = ( { mask, index, currentSlide }) => {
  const ref = useRef()
  
  return (
  <div
    className={`${styles.slide} ${ index === currentSlide ? styles.active : "" } keen-slider__slide`}
    key={index} >
      <div className={`${styles.inner}`}>
        <img src={mask.icon} />
        <h5>{mask.name}</h5>
        <p>{mask.desc}</p>
      </div>
  </div>
  );
};

export default MaskCarouselImage;
