import s from "./BookLink.module.scss";
import book_link_img from "../../../../assets/book/book_link.png";

const BookLink = (props) => {
  return (
    <div className={s.container}>
      <div className={s.contents}>
        <div className={s.left_section}>
          <h1>NFT BOOK</h1>
          <p>
           {`Jordi Molla’s book is itself a remarkable work of art. It is a small
            edition number of very high quality books that this artist created
            for his inner circle of collectors and fellow artists. It gives us
            an intimate glimpse into Jordi’s home, atelier and his working
            process, a raw, poignant day by day diary that brings the viewers a
            personal and unfiltered behind the scenes view.`}
          </p>
          <a className={s.button} href="/book">
            View website
          </a>
        </div>
        <div className={s.right_section}>
          <img src={book_link_img} />
        </div>
      </div>
    </div>
  );
};

export default BookLink;
