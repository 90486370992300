import { useEffect, useRef, useState } from "react";
import styles from "./Header.module.scss";
import MainWrapper from "../UI/MainWrapper/MainWrapper";
import Burger from "../../assets/Burger";
import Close from "../../assets/Close";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Arrow from "../../assets/Arrow";
import AccountButton from "../AccountButton/AccountButton";
import { OpenSeaButton, OpenSeaIcon } from "../OpenSeaButton/OpenSeaButton";
import auctionStyles from "../AuctionUI/AuctionUI.module.scss";
import jazzicon from "@metamask/jazzicon";

const Link = (props) => {
  return props.prefix ? (
    <a {...props} href={props.prefix + props.href}>
      {props.children}
    </a>
  ) : (
    <AnchorLink {...props}>{props.children}</AnchorLink>
  );
};

function Header(props) {
  const [menuStatus, setMenu] = useState(false);
  const jazziconRef = useRef();

  const { accountAddress, ethBalance } = props.wallet;

  useEffect(() => {
    if (accountAddress) {
      console.log("getting jazzicon");
      const icon = jazzicon(16, parseInt(accountAddress?.slice(2, 10), 16));
      const current = jazziconRef.current;
      current?.appendChild(icon);
      return () => {
        current?.removeChild(icon);
      };
    }
  }, [accountAddress]);
  const shortAddress =
    accountAddress?.substr(0, 6) +
    "..." +
    accountAddress?.substr(accountAddress.length - 4);
  return (
    <header
      className={`${styles.Header} ${
        props.type === "home" ? styles.home : styles.auction
      }`}
    >
      <MainWrapper>
        <nav>
          <div className={styles.burger} onClick={() => setMenu(true)}>
            <Burger />
          </div>
          <div className={styles.left}>
            <Link prefix={props.prefix} href="#home-section">
              Home
            </Link>
            <Link prefix={props.prefix} href="#about-section">
              About
            </Link>

            {props.type === "home" ? (
              <Link prefix={props.prefix} href="#krew-section">
                Krew studios
              </Link>
            ) : (
              <Link prefix={props.prefix} href="#jordi-section">
                Jordi Molla
              </Link>
            )}
          </div>
          <div className={styles.logo}></div>
          <div className={styles.right}>
            {props.type === "home" ? (
              <>
                <Link prefix={props.prefix} href="#video-section">
                  Project
                </Link>
                <Link prefix={props.prefix} href="#collection-section">
                  Mint
                </Link>
                <OpenSeaButton
                  href="https://opensea.io/collection/jordimolla-masks"
                  target="_blank"
                >
                  <OpenSeaIcon />
                  OpenSea
                </OpenSeaButton>
                {/* <a target="_blank" href="http://google.com" className={styles.externalLink}>Check all<Arrow /></a> */}
                {/* <AccountButton wallet={props.wallet} /> */}
              </>
            ) : (
              <>
                <Link prefix={props.prefix} href="#roadmap-section">
                  Roadmap
                </Link>
                <Link prefix={props.prefix} href="#krew-section">
                  Krew studios
                </Link>
                <AccountButton wallet={props.wallet} />
              </>
            )}
          </div>
        </nav>
      </MainWrapper>
      {menuStatus ? (
        <div className={styles.mobileMenu}>
          <div className={styles.close} onClick={() => setMenu(false)}>
            <Close />
          </div>
          <div className={styles.links}>
            <Link
              prefix={props.prefix}
              onClick={() => setMenu(false)}
              href="#home-section"
            >
              Home
            </Link>
            <Link
              prefix={props.prefix}
              onClick={() => setMenu(false)}
              href="#about-section"
            >
              About
            </Link>
            <Link
              prefix={props.prefix}
              onClick={() => setMenu(false)}
              href="#jordi-section"
            >
              Jordi Molla
            </Link>
            <Link
              prefix={props.prefix}
              onClick={() => setMenu(false)}
              href="#roadmap-section"
            >
              Roadmap
            </Link>
            <Link
              prefix={props.prefix}
              onClick={() => setMenu(false)}
              href="#krew-section"
            >
              Krewstudios
            </Link>
            <div className={styles.WalletButtonDiv}>
              {accountAddress && (
                <div className={styles.AccountDetails}>
                  <div className={styles.ButtonText}>{ethBalance} ETH</div>
                  <div className={styles.Address}>
                    <div></div>
                    <div className={styles.MonospaceText}>{shortAddress}</div>
                    <div
                      className={styles.JazziconContainer}
                      ref={jazziconRef}
                    ></div>
                  </div>
                </div>
              )}

              {accountAddress ? (
                <button
                  className={auctionStyles.BidButton}
                  onClick={props.wallet.disconnectWallet}
                >
                  {" "}
                  Disconnect wallet
                </button>
              ) : (
                <button
                  className={auctionStyles.BidButton}
                  onClick={props.wallet.connectWallet}
                >
                  {" "}
                  Connect wallet
                </button>
              )}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </header>
  );
}

export default Header;
